import { SvgIconProps, SvgIcon } from '@material-ui/core';

export const TwitterFilledCircled: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox='0 0 20.738 20.738'>
    <path
      d='M10.369,0A10.369,10.369,0,1,0,20.737,10.369,10.369,10.369,0,0,0,10.369,0ZM15.1,8.085q.007.153.007.307a6.725,6.725,0,0,1-6.772,6.772h0A6.736,6.736,0,0,1,4.69,14.095a4.841,4.841,0,0,0,.568.033,4.775,4.775,0,0,0,2.956-1.019,2.384,2.384,0,0,1-2.223-1.654,2.372,2.372,0,0,0,1.075-.041A2.384,2.384,0,0,1,5.154,9.084c0-.011,0-.021,0-.03a2.364,2.364,0,0,0,1.078.3A2.384,2.384,0,0,1,5.5,6.172,6.757,6.757,0,0,0,10.4,8.659a2.384,2.384,0,0,1,4.059-2.17,4.773,4.773,0,0,0,1.511-.58,2.39,2.39,0,0,1-1.047,1.316,4.747,4.747,0,0,0,1.366-.375A4.836,4.836,0,0,1,15.1,8.085Zm0,0'
      transform='translate(0.001)'
    />
  </SvgIcon>
);
