import { SvgIconProps, SvgIcon } from '@material-ui/core';

export const StarOutlined: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox='0 0 46.664 45.053'>
    <path
      d='M26.114,4.372l5.651,11.3a4.334,4.334,0,0,0,1.256.942L45.266,18.5a1.723,1.723,0,0,1,.942,2.826l-8.791,8.791c-.314.314-.628.942-.314,1.256l2.2,12.244a1.5,1.5,0,0,1-2.2,1.57L26.114,39.535a1.4,1.4,0,0,0-1.57,0L13.556,45.186a1.452,1.452,0,0,1-2.2-1.57l2.2-12.244c0-.628,0-.942-.314-1.256L3.509,20.7a1.452,1.452,0,0,1,.942-2.512L16.7,16.3c.628,0,.942-.314,1.256-.942L23.6,4.058A1.386,1.386,0,0,1,26.114,4.372Z'
      transform='translate(-1.524 -1.882)'
      fill='none'
      stroke='currentColor'
      strokeMiterlimit='10'
      strokeWidth={3}
    />
  </SvgIcon>
);
