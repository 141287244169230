import { SvgIconProps, SvgIcon } from '@material-ui/core';

export const LockOutlined: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox='0 0 36 42.545'>
    <g transform='translate(-995 -936)'>
      <path
        d='M37.727,16.091H34.455V14.455a11.455,11.455,0,1,0-22.909,0v1.636H8.273A3.273,3.273,0,0,0,5,19.364V42.273a3.273,3.273,0,0,0,3.273,3.273H37.727A3.273,3.273,0,0,0,41,42.273V19.364A3.273,3.273,0,0,0,37.727,16.091ZM14.818,14.455a8.182,8.182,0,0,1,16.364,0v1.636H14.818ZM8.273,42.273V19.364H37.727V42.273Z'
        transform='translate(990 933)'
        fill='#7a151b'
      />
      <rect width='4' height='8' transform='translate(1011 958)' />
    </g>
  </SvgIcon>
);
