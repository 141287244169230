import { useGlobalStyles } from '@/styles';
import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import PostRequest from 'src/logic/utils/create-request/Helpers/PostRequest';
import { LanguageContext } from 'src/ui/components/layout';

interface Props {}

export const HomeSubscribe: React.FC<Props> = () => {
  const gs = useGlobalStyles();
  const [Email, setEmail] =useState('');
  const [Notif, setNotif] =useState<any>(false);
  const [NotifS, setNotifS] =useState<any>(false);
  const language = useContext(LanguageContext);
 useEffect(() => {
      let label = document.querySelector('label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-filled.MuiFormLabel-root.MuiFormLabel-colorSecondary.css-9lz86c-MuiFormLabel-root-MuiInputLabel-root')
      // @ts-ignore
      if(label && language.id === 1) {
        // @ts-ignore
        label.style.width = '100%';
        // @ts-ignore
        label.style.textAlign = 'end';
      }else{
        // @ts-ignore
        label.style.width = '100%';
        // @ts-ignore
        label.style.textAlign = 'start';

      }
 }, [language.id]);

 const emailChange = (e: any) => {
  e.preventDefault();
  setEmail(e.target.value);
 }
 const sub = async () => {
  console.log('Sub Email:', Email);
  const res = await PostRequest('subscribe', {
    email: Email,
    reciveEmail: true,
  });
  if(res.data.success){
    setNotif(false);
    setNotifS(true);
  }else{
    setNotifS(false);
    setNotif(true);
  }
 }
  return (
    <Box
      className={gs.responsiveContainer}
      py={4}
      sx={{ backgroundColor: t => t.palette.primary.main }}
      style={{direction: language.id === 1 ? 'rtl':'ltr'}}
    >
       <Snackbar open={Notif} onClose={setNotif} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} autoHideDuration={3000} anchorOrigin={{vertical:'top', horizontal :'center'}}>
            {language.id === 1? 
            <Alert severity="error" style={{fontSize: '16px'}} >
            عذرا! يرجى ادخال بريد الكتروني صحيح
          </Alert>
            :<Alert severity="error" style={{fontSize: '16px'}} >
              Sorry! Please enter valid email address
            </Alert>}
        </Snackbar>
       <Snackbar open={NotifS} onClose={setNotifS} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} autoHideDuration={3000} anchorOrigin={{vertical:'top', horizontal :'center'}}>
            {language.id === 1? 
            <Alert severity="success" style={{fontSize: '16px'}} >
            تم متابعة بريد الأخبار بنجاح
          </Alert>
            :<Alert severity="success" style={{fontSize: '16px'}} >
              You email subscribed successfully.
            </Alert>}
        </Snackbar>
      <Grid container spacing={3} alignItems='center'>
        <Grid item lg={6} xs={12}>
          <Typography variant='h4' color='white' fontWeight='bold' style={{direction: language.id === 1 ? 'rtl':'ltr'}} >
            {language.id === 1 ?  'اشترك في جريدة الأخبار لتبقى على اطلاع على احدث المستجدات':'Subscribe To Newsletter To Stay Up To Date On Our Latest News'}
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Stack direction='row' spacing={2} alignItems='center'>
            <TextField
              label={language.id === 1  ?  'بريدك الاركتروني' :'Your Email'}
              variant='filled'
              color='secondary'
              onChange={emailChange}
              inputProps={{ sx: { backgroundColor: 'white' } }}
              fullWidth
            />
            <Button
              variant='outlined'
              color='secondary'
              size='large'
              onClick={sub}
              sx={{
                py: 2,
                px: 5,
                fontWeight: 500,
              }}
            >
             {language.id === 1? 'اشترك': 'SUBSCRIBE'}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
