import { useGlobalStyles } from '@/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import React , {useState, useEffect, useContext}from 'react';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import { CategoryCard } from '@/components';
import { Link } from 'src/ui/components/shared';
import { siteMap } from 'src/site-map';
import { LanguageContext } from 'src/ui/components/layout';

interface Props {}

export const HomeOurBusiness: React.FC<Props> = () => {
  const gs = useGlobalStyles();
  const language = useContext(LanguageContext);

  const [content, setContent] = useState([]);
  useEffect(() => {
    const request = async ()=>{
      let res = await GetRequest('categories_parents');
      setContent(res.data);
    }
    request();
  }, [language.id])

  return (
    <Box className={gs.responsiveContainer}>
      <Typography variant='h4' mb={2} style={{textAlign: language.id === 1 ? 'end':'start'}} >
       {language.id === 1? 'أعمالنا' :<> Our <b>Business</b></>}
      </Typography>
      {
        [...Array(Math.ceil(content.length/5)).keys()].map((i: any) => (
              <Grid container spacing={2} key={i} >
                {content.slice(5*i,5*(i+1)).map((el: any) => (
                  <Grid item xl lg xs={12} key={el.id}>
                    <Link
                      underline='none'
                      href={{
                        pathname: siteMap.businesses.slug(),
                        query: { slug: `${el.description}_${el.name}` },
                      }}
                    >
                      <CategoryCard title={el.description} src={el.image} />
                    </Link>
                  </Grid>
        ))}
      </Grid>
        ))
      }
    </Box>
  );
};
