import { Box, Grid, Popover, Stack } from '@material-ui/core';
import { Link } from '../../../../shared';
import { exist } from '@/utils';
// import Image from 'next/image';
// import { businesses } from '@/models';
import { useGlobalStyles } from '@/styles';
import { siteMap } from 'src/site-map';
import React, { useState, useEffect } from 'react';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';

type Props = {
  anchorEl?: any | undefined;
  onClose?: () => void;
};

export const BusinessPopover: React.FC<Props> = ({ anchorEl, onClose }) => {
  const gs = useGlobalStyles();
  const [content, setContent] = useState([]);
  useEffect(() => {
    const request = async () => {
      let res = await GetRequest('categories_parents');
      setContent(res.data);
    }
    request();
  }, [])

  return (
    <Popover
      open={exist(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorReference='anchorPosition'
      anchorPosition={{ top: 132, left: 0 }}
      className='business-menu'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{ sx: { width: '100%', maxHeight: 'calc(100vh - 150px)' } }}
    >
      <Box className={gs.responsiveContainer} py={6} onMouseLeave={onClose} >
        <Grid container className='here' >
          {
            content.map((c: any) => (
              <Grid item xl key={c.id}>
                <Grid container >
                  <Grid item xl={7}>
                    <Stack spacing={2}>
                      <Link
                        style={{fontWeight: 'bolder', borderRadius: '3px', borderBottom: '1px solid'}}
                        href={c.have_child ? {
                          pathname: siteMap.businesses.slug(),
                          query: { slug: `${c.description}_${c.name}` },
                        } : {
                          pathname: siteMap.products.category(),
                          query: { category: c.name },
                        }}
                        underline='hover'
                        fontWeight={500}
                      >
                        {c.description}
                      </Link>
                      {c.children.map((el: any) => (
                        <Link
                          key={el.id}
                          href={el.have_child ? {
                            pathname: siteMap.businesses.slug(),
                            query: { slug: `${el.description}_${el.name}` },
                          } : {
                            pathname: siteMap.products.category(),
                            query: { category: el.name },
                          }}
                          underline='hover'
                        >
                          {el.description}
                        </Link>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </Popover>
  );
};
