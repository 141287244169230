// @ts-nocheck
import { createStore } from '@/utils';

type Directions = 'ltr' | 'rtl';
type Languages = 'en' | 'ar';

export type AppState = {
  direction: Directions;
  lang: Languages;
};

type AppActions = {
  setDirection: (dir: Directions) => void;
  setLanguage: (lang: Languages) => void;
};

const initialState: AppState = {
  direction: 'ltr',
  lang: 'en',
};

export const useApp = createStore('App')<AppState, AppActions>(
  initialState,
  (  set: (arg0: { (state: any): void; (state: any): void; }) => any) => ({
    setDirection: (dir: any) =>
      set((state: { direction: any; }) => {
        state.direction = dir;
      }),

    setLanguage: (lang: any) =>
      set((state: { lang: any; }) => {
        state.lang = lang;
      }),
  })
);
