import {
  ApiError,
  ApiErrorEnum,
  ApiRequestError,
  ApiResponseError,
  ApiUnknownError,
} from '@/types';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { toast } from 'react-toastify';
import { logError } from '.';
import { axiosInstance } from './axios-instance';

// types
type QueryKey = unknown[];

export const createQuery =
  <T extends object = any>(
    baseKey: QueryKey,
    baseConfig?: AxiosRequestConfig,
    baseOptions?: UseQueryOptions<T, ApiError, T, QueryKey>
  ) =>
  (
    hookConfig?: AxiosRequestConfig,
    hookOptions?: UseQueryOptions<T, ApiError, T, QueryKey>,
    onSuccess?: (data: T) => void,
    onError?: (err: ApiError) => void
  ) =>
    useQuery<T, ApiError, T, QueryKey>(
      baseKey,
      async () => {
        try {
          const { data } = await axiosInstance.request<T>({
            ...baseConfig,
            ...hookConfig,
          });
          onSuccess && onSuccess(data);
          return data;
        } catch (e) {
          const err = <AxiosError<ApiError>>e;

          if (err.response) {
            logError('recived response', JSON.stringify(err.response, null, 2));

            onError &&
              onError({
                ...err.response.data,
                errorType: ApiErrorEnum.response,
              } as ApiResponseError);

            // const mess = i18next.t(err.response.status.toString(), {
            //   ns: 'api-errors',
            // });
            const mess = 'error in request';

            toast(mess, { type: 'error' });

            throw {
              ...err.response.data,
              errorType: ApiErrorEnum.response,
            } as ApiResponseError;
          } else if (err.request) {
            logError(
              'WITH NO RESPONSE, the request',
              JSON.stringify(err.request, null, 2)
            );

            onError &&
              onError({
                errorType: ApiErrorEnum.request,
                request: err.request,
              } as ApiRequestError);

            // const mess = i18next.t('network', {
            //   ns: 'api-errors',
            // });

            const mess = 'error in request';

            toast(mess, { type: 'error' });

            throw {
              errorType: ApiErrorEnum.request,
              request: err.request,
            } as ApiRequestError;
          } else {
            logError(
              'unhandled error happend, the message form axios request',
              JSON.stringify(err.message, null, 2)
            );

            onError &&
              onError({ errorType: ApiErrorEnum.unknown } as ApiUnknownError);

            // const mess = i18next.t('unknown', {
            //   ns: 'api-errors',
            // });

            const mess = 'error in request';

            toast(mess, { type: 'error' });

            throw { errorType: ApiErrorEnum.unknown } as ApiUnknownError;
          }
        }
      },
      {
        ...baseOptions,
        ...hookOptions,

        onError: (...args) => {
          baseOptions?.onError?.(...args);
          hookOptions?.onError?.(...args);
        },
        onSuccess: (...args) => {
          baseOptions?.onSuccess?.(...args);
          hookOptions?.onSuccess?.(...args);
        },
        onSettled: (...args) => {
          baseOptions?.onSettled?.(...args);
          hookOptions?.onSettled?.(...args);
        },
      }
    );
