import { SvgIconProps, SvgIcon } from '@material-ui/core';

export const FacebookFilledCircled: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox='0 0 21.057 20.735'>
    <path
      id='Path_1424'
      data-name='Path 1424'
      d='M295.747,508.668q-2.438.4-4.9.733Q293.309,509.066,295.747,508.668Zm0,0'
      transform='translate(-274.69 -490.472)'
    />
    <path
      id='Path_1425'
      data-name='Path 1425'
      d='M301.671,507.793c-.777.143-1.554.274-2.331.408C300.117,508.067,300.894,507.936,301.671,507.793Zm0,0'
      transform='translate(-281.465 -490.264)'
    />
    <path
      id='Path_1426'
      data-name='Path 1426'
      d='M283.347,510.34q-2.853.322-5.734.566Q280.491,510.664,283.347,510.34Zm0,0'
      transform='translate(-262.307 -490.871)'
    />
    <path
      id='Path_1427'
      data-name='Path 1427'
      d='M289.2,509.738c-.914.122-1.834.235-2.75.342C287.362,509.971,288.285,509.86,289.2,509.738Zm0,0'
      transform='translate(-269.262 -490.727)'
    />
    <path
      id='Path_1428'
      data-name='Path 1428'
      d='M308.723,506.41c-.685.146-1.372.292-2.062.432C307.35,506.7,308.039,506.556,308.723,506.41Zm0,0'
      transform='translate(-288.368 -489.935)'
    />
    <path
      id='Path_1429'
      data-name='Path 1429'
      d='M326.146,502.113c-.553.158-1.107.307-1.661.461C325.038,502.42,325.59,502.271,326.146,502.113Zm0,0'
      transform='translate(-305.294 -488.913)'
    />
    <path
      id='Path_1430'
      data-name='Path 1430'
      d='M320.869,503.543c-.6.158-1.205.318-1.81.47Q319.965,503.786,320.869,503.543Zm0,0'
      transform='translate(-300.16 -489.253)'
    />
    <path
      id='Path_1431'
      data-name='Path 1431'
      d='M313.989,505.254c-.637.149-1.28.289-1.923.432C312.709,505.541,313.352,505.4,313.989,505.254Zm0,0'
      transform='translate(-293.493 -489.661)'
    />
    <path
      id='Path_1432'
      data-name='Path 1432'
      d='M276.564,511.09q-1.54.129-3.084.232Q275.026,511.22,276.564,511.09Zm0,0'
      transform='translate(-256.927 -491.049)'
    />
    <path
      id='Path_1433'
      data-name='Path 1433'
      d='M20.736,10.368A10.367,10.367,0,1,0,10.368,20.736h.182V12.662H8.323v-2.6H10.55V8.157a3.118,3.118,0,0,1,3.329-3.421,18.336,18.336,0,0,1,2,.1V7.152H14.513c-1.075,0-1.283.511-1.283,1.261v1.653H15.8l-.338,2.6H13.227v7.672a10.372,10.372,0,0,0,7.509-9.967Zm0,0'
      transform='translate(-0.001 -0.001)'
    />
    <path
      id='Path_1434'
      data-name='Path 1434'
      d='M271,511.43q-3.045.2-6.114.3Q267.958,511.631,271,511.43Zm0,0'
      transform='translate(-250.156 -491.13)'
    />
    <path
      id='Path_1435'
      data-name='Path 1435'
      d='M263.743,511.836q-1.616.053-3.239.081Q262.125,511.889,263.743,511.836Zm0,0'
      transform='translate(-244.477 -491.226)'
    />
  </SvgIcon>
);
