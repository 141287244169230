import React, { useEffect } from 'react';
import { 
     CloseOutlined,
     CloudUploadOutlined ,
     InsertDriveFileOutlined
    } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { useState } from 'react';

interface Props {
    label: string;
    name: string;
    onChange: any;
    onRemove: any;
    files?: any;
}
const FileUploader = ({label,name,onChange,onRemove, files}: Props) => {
    const [Updated, setUpdated] = useState<boolean>(false)
    const style: any = {
        container: {
            position: 'relative',
            width: '180px',
            maxWidth: '100%',
            border: '1px dotted gray',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            cursor: 'pointer',
            maxHeight: '180px',
            height: '160px',
            justifyContent: 'space-evenly',
        }
    }
    const handleOpen = () => {
        let _input = document.getElementById(name);
        _input?.click();
    }
    const handleUpload = (e:any) => {
        let _file :any= e.target.files[0];
        onChange(name,_file);
    }
    const handleRemove = (e:any) => {
        e.stopPropagation();        
        onRemove(name);
        setUpdated(!Updated);
    }
    useEffect(() => {
        
        console.log('Updated');
    }, [Updated])

    return <div className='inp-container' style={style.container} onClick={handleOpen} >
        {files[name] ?<CloseOutlined onClick={handleRemove} style={{position: 'absolute',top: '0',right: '0',margin: '10px'}} />: ''}
        <input type='file' id={name} style={{display: 'none'}} onChange={handleUpload} />
                {files[name] ? <InsertDriveFileOutlined /> : <CloudUploadOutlined />}
         <Typography variant='body2' pt={2} textAlign='center'>
                {files[name] ? files[name].name : label}
         </Typography>
    </div>
};

export default FileUploader;