import { Box, Typography } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import moment from 'moment';
import Image from 'next/image';
import React from 'react';

type Props = {
  id: string;
  title: string;
  src: string;
  description: string;
  time: string;
};

export const PostCard: React.FC<Props> = ({
  title,
  description,
  src,
  time,
}) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        height: '100%',
        transition: '0.27s',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#00000009',
        '&:hover': {
          backgroundColor: t => t.palette.secondary.main,
          transform: 'scale(1.02)',
          boxShadow: t => t.shadows[4],
        },
      }}
    >
      <div>
        <Box position='relative' height={330}>
          <Image src={src} alt={title} layout='fill' objectFit='cover' />
        </Box>
        <Box p={3}>
          <Typography variant='h6'>{title}</Typography>
          <Typography variant='body1'>{description}</Typography>
        </Box>
      </div>
      <Typography variant='body2' p={3}>
        <AccessTime
          color='secondary'
          sx={{
            mixBlendMode: 'multiply',
          }}
        />{' '}
        {moment(time).fromNow()}
      </Typography>
    </Box>
  );
};
