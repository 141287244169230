import { useGlobalStyles } from '@/styles';
import { Box, IconButton, Stack } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import React, { useEffect, useState } from 'react';
import { Link, Logo } from '../../shared';
import { Notifications, Person, Search, ShoppingCart } from '@material-ui/icons';
import { BusinessPopover, LoginPopover, SearchDialog } from './menus';
import { siteMap } from 'src/site-map';
import { useRouter } from 'next/router';
import { useCallback, useContext } from 'react';
import { LanguageContext } from '../main-layout';
import useStore from 'src/logic/utils/create-store/cart-store';
import useLogStore from 'src/logic/utils/create-store/user-store';
import PostRequest from 'src/logic/utils/create-request/Helpers/PostRequest';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import { NotificationsPopover } from './menus/notifications';


export const DesktopNavbar = ({logo}: any) => {
  const gs = useGlobalStyles();
  const { asPath } = useRouter();
  const language = useContext(LanguageContext);

  const [user, setUser] = useState<any>({username: '-'});
  const [loginErr, setLoginErr] = useState<any>(false);
  const [Msg, setMsg] = useState<any>('');
  const [nNotify, setnNotify] = useState<any>(0);
  const [anchors, setAnchors] = useState<{
    business?: any | undefined;
    login?: any | undefined;
    search?: any | undefined;
    notifications?: any | undefined;
  }>({});

  const setAnchor = useCallback(
    (key: keyof typeof anchors) =>
      (e: React.MouseEvent<HTMLElement, MouseEvent>) => 
        setAnchors(v => ({ ...v, [key]: e.currentTarget })),
    []
  );

  const delAnchor = useCallback(
    (key: keyof typeof anchors) => () =>
      setAnchors(v => ({ ...v, [key]: undefined })),
    []
  );

  useEffect(() => {
    setAnchors({});
  }, [asPath,]);
  useEffect(() => {
  }, [logo]);

  const products = useStore((state: any) => state.products);
  const userStore = useLogStore((state: any) => state);
  useEffect(() => {}, [language.id,products.length,nNotify]);
  const token = localStorage.getItem('token');
  useEffect(() => {
    const req = async () => {
      if(token){
        try {
          const res = await GetRequest('profile');
          console.log('Profile:', res.data);
          userStore.setProfile(res.data);
          setUser(res.data)
        } catch (error) {
            localStorage.setItem('token', '');
            console.log(error)
            // location.reload();
        }
      }
    }
    req();
  }, [token]);

  useEffect(() => {

  }, [userStore.profile])
  return (
    <Box
      className={gs.responsiveContainer}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      bgcolor='white'
      sx={{
        padding: t => t.spacing(2, 0),
        boxShadow: t => t.shadows[4],
      }}
    >
      <div>
        <Stack direction='row' spacing={[0, 0, 2, 3, 6]} alignItems='center'>
          <Link href={siteMap.home}>
            <Logo logo={logo} />
          </Link>
          {[
            ['', "الرئيسية",'Home', siteMap.home],
            ['',"حول",'About', siteMap.aboutUs],
            ['','الأعمال','Business', '/business'],
            ['','التوظيف','Careers', siteMap.careers],
            ['','الأخبار','News', siteMap.news.index],
            ['','تواصل معنا','Contact Us', siteMap.contactUs],
          ].map(el =>
            el[3] === '/business' ? (<span className='top-nav-item' onMouseLeave={delAnchor('business')}>
              <Link
                href='#'
                key={el[2]}
                underline='hover'
                color='primary'
                onMouseEnter={setAnchor('business')}
              >
                {el[language.id]}
              </Link>
              <BusinessPopover
                    anchorEl={anchors.business}
                    onClose={delAnchor('business')}
                  />
              </span>
            ) : (
              <Link className='top-nav-item' key={el[2]} href={el[3]} underline='hover' color='primary' onMouseEnter={delAnchor('business')} >
                {el[language.id]}
              </Link>
            )
          )}
        </Stack>
      </div>
      <div>
        <Stack direction='row' spacing={1} alignItems='center'>
          <IconButton color='primary' onClick={setAnchor('search')}>
            <Search />
          </IconButton>

          <Link href={siteMap.checkOut.index}>
            <IconButton color='primary'>
              <Badge badgeContent={products.length} color='primary' >
              <ShoppingCart />
              </Badge>
            </IconButton>
          </Link>
          {
            (token) ?<IconButton color='primary' onClick={setAnchor('notifications')} >
                <Badge badgeContent={nNotify} color='primary' >
                  <Notifications  />
                </Badge>
              </IconButton> :''
          }
          {token ? (
            <Link color='primary' href={siteMap.profile.index}>
              {user.username}
            </Link>
          ) : (
            <IconButton color='primary' id='open-login' onClick={setAnchor('login')}>
              <Person />
            </IconButton>
          )}
        </Stack>
      </div>
      <SearchDialog anchorEl={anchors.search} onClose={delAnchor('search')} />
      <NotificationsPopover
        anchorEl={anchors.notifications}
        onClose={delAnchor('notifications')}
        setnCount={setnNotify}
      />
      <LoginPopover
        anchorEl={anchors.login}
        loginErr={loginErr}
        Msg={Msg}
        onClose={delAnchor('login')}
        onLogin={async data => {
          const res = await PostRequest('login', data);
          console.log(res.data);
          
          res.data.message && setMsg(res.data.message);          
         if(res.data?.data?.access_token){
           setAnchors({ login: undefined });
           localStorage.setItem('token',res.data.data.access_token);
           const resp = await GetRequest('profile');
           userStore.setProfile(resp.data);
           setUser(resp.data);
           userStore.setLogIn();
           setLoginErr(false);
         }else{
          setLoginErr(true);
         }
        }}
      />
    </Box>
  );
};
