import { ImagesDropzone, Link } from '@/components';
import { useGlobalStyles } from '@/styles';
import {
  Stack,
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Snackbar,
  Alert,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { siteMap } from 'src/site-map';
import { GoogleMapComponent } from 'src/ui/components/shared';
import { AuthSharedLayout } from '../../auth';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PostRequest from 'src/logic/utils/create-request/Helpers/PostRequest';
import useLogStore from 'src/logic/utils/create-store/user-store';
import { useRouter } from 'next/router'
import { LanguageContext } from 'src/ui/components/layout';
import { 
  // CloudUploadRounded,
   CancelOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import Image from 'next/image';
import FileUploader from 'src/ui/components/dumb/File_Uploader';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import { DatePicker } from '@material-ui/lab';
import axios from 'axios';
import MuiPhoneNumber from 'material-ui-phone-number';




type BaseInputs = {
  profilePhoto: File;
  username: string;
  first_name: string;
  last_name: string;
  password: string;
  password_confirmation: string;
  email?: string;
  phone: string;
  birth: string;
  gender: 'male' | 'female' | 'others';

  location?: { lat: number; lng: number };
  mapLocation?: string;
  unit_type_id?: string;
  country: string;
  city: string;
  avenue?: string;
  street?: string;
  block?: string;
  house_number?: string;
  special_directions?: string;
};
type PersonalInputs = BaseInputs;
type BusinessInputs = BaseInputs & {
  registrationForm: File;
  company_name: string;
  position: string;
  paymentMethod: '' | 'credit';
} & (
    | {
        paymentMethod: '';
      }
    | {
        paymentMethod: 'credit';
        card_limit: string;
        notes?: string;
      }
  );

const personalSchema = {
  profilePhoto: yup.object().notRequired(),
  username: yup.string().required('This field required'),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  password: yup.string().min(8).required('This field required'),
  password_confirmation: yup
    .string()
    .required('This field required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
  email: yup.string().required('This field required'),
  country: yup.string().required('This field required'),
  city: yup.string().required('This field required'),
  phone: yup.string().notRequired(),
  birth: yup.date().required('This field required'),
  gender: yup.string().notRequired(),
  location: yup.object().notRequired().shape({
    lng: yup.string().notRequired(),
    lat: yup.string().notRequired(),
  }),
  mapLocation: yup.string().notRequired(),
  unit_type_id: yup.string().notRequired(),
  avenue: yup.string().notRequired(),
  street: yup.string().notRequired(),
  block: yup.string().notRequired(),
  house_number: yup.string().notRequired(),
  special_directions: yup.string().notRequired(),
};
const businessSchema = {
  ...personalSchema,
  registrationForm: yup.object(),
  company_name: yup.string().required('This field required'),
  position: yup.string().required('This field required'),
  paymentMethod: yup.string().notRequired().oneOf(['', 'credit']),
  card_limit: yup.string().notRequired(),
  notes: yup.string().notRequired(),
};

type Props = {
  type: 'personal' | 'business';
};

export const SignUpSharedPage: React.FC<Props> = ({ type }) => {
  const gs = useGlobalStyles();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [ValidMsg, setValidMsg] = useState('');
  const [CountryCode, setCountryCode] = useState('us');
  const [Error, setError] = useState<any>(false);
  const [ErrorValid, setErrorValid] = useState<any>(false);
  const [Loading, setLoading] = useState<any>(false);
  const [Msg, setMsg] = useState<any>({errors: { email: '', username: '', phone: ''}});
  const [files, setfiles] = useState<any>({});
  const [InValids, setInValids] = useState<any>({});
  const [Location, setLocation] = useState<any>({
    long: 47.990341, latt: 29.378586
  });
  const router = useRouter();
  const language = useContext(LanguageContext);
  const [image, setImage] = useState<any>({
   src: ''
  });
  const [imageFile, setImageFile] = useState<any>([]);
  const [UnitTypes, setUnitTypes] = useState<any>([]);
  const [Countries, setCountries] = useState<any>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [File,setFile] = useState<any>('');
const valids = {
  first_name: ['PLease Fill First Name !', 'يرجى ملئ حقل الأسم الأول'],
  last_name: ['PLease Fill Last Name !', 'يرجى ملئ حقل الأسم الأخير'],
  email: ['PLease Fill Email !', 'يرجى ملئ حقل البريد الالكتروني '],
  bDate: ['PLease Fill Birth Date !', 'يرجى ملئ حقل تاريخ الولادة '],
  'custom-phone': ['PLease Fill Mobile NUmber !', 'يرجى ملئ حقل رقم الجوال'],
  'the-user-name': ['PLease Fill Username !', 'يرجى ملئ حقل أسم المستخدم'],
  gender: ['PLease Fill Your Gender !', 'يرجى ملئ حقل الجنس '],
  country: ['PLease Fill Your Country !', 'يرجى ملئ حقل الدولة '],
  city: ['PLease Fill Your City !', 'يرجى ملئ حقل المدينة '],
  'date-valid': ['PLease Enter A Valid Date Format!', 'يرجى ادخال صيغة تاريخ صحيحة'],
}
const handleClickShowPassword = () => setShowPassword(!showPassword);
const handleMouseDownPassword = () => setShowPassword(!showPassword);
useEffect(() => {
  const request = async ()=>{
    let res = await GetRequest('get_docs');
    if(res && res.success){
      console.log(res.data);
      let _file = res.data.find((f:any) => f.name === "registration_form");
      if(_file){
        setFile(_file.doc);
      }
    }
  }
  request();

}, [])
  useEffect(() => {
    // Get Country Code
    axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;      
      setCountryCode(data.country_code.toLocaleLowerCase());
  }).catch((error) => {
      console.log(error);
  });
  }, [])
  useEffect(() => {
    const cPhone = document.getElementById('custom-phone');
    console.log('custom-phone: ', cPhone);
  }, [language.id])
  const schema = useMemo(
    () =>
      yup.object().shape(type === 'personal' ? personalSchema : businessSchema),
    [type]
  );

  const { register, handleSubmit , setValue, getValues} = useForm<BusinessInputs | PersonalInputs>({
    resolver: yupResolver(schema) as any,
  });
  // @ts-ignore
  const [_bDate, set_bDate] = useState<any>('');

  useEffect(() => {
    // @ts-ignore
    let _date = getValues('birth') as Date;
    // @ts-ignore
  }, [_bDate]);
  const userStore = useLogStore((state: any) => state);
  useEffect(() => {
  }, [imageFile])
  const onSubmit = useCallback<SubmitHandler<BusinessInputs | PersonalInputs>>(data => {
    console.log('>>',files);
    setInValids({})
    const send = async () => {
      // @ts-ignore
      let payload: any = {...data, email: data.email.trim(), account_type_id: type === 'personal' ? 1 :2, birth: data.birth.toISOString().split('T')[0]}
      payload.image = imageFile[0];
      if(!payload.image) delete payload.image;
      let formData= new FormData();
      setLoading(true);
      for (let key in payload){
        payload[key] && formData.append(key,payload[key]);
      }
      Location.latt && formData.append('address_lat',Location.latt);
      Location.long && formData.append('address_lng',Location.long);
      Location.long && Location.latt && formData.append('map_location',`${Location.long}, ${Location.latt}`);
      for (let key in files){
        formData.append(key,files[key]);
      }
      console.log('formData', payload.phone);
      
      const response = await PostRequest('registration', formData);
      console.log(response);
      if(response.data.success){
        let _profile : any = data;
        delete _profile?.password;
        delete _profile?.password_confirmation;
        userStore.setProfile({..._profile ,account_type_id: type === 'personal' ? 1 :2});
        setTimeout(() => {
          router.push('/auth/confirm-email')
        }, 2000); 
      }else{
        setMsg(response.data);
        setError(true);
        setLoading(false);
      }
    }
    send();
  }, [imageFile,files]);
  useEffect(() => {
    const request = async () => {
      try {
        const res = await GetRequest('ddl_unit_types');
        setUnitTypes(res.data);
        
      } catch (error) {
        
      }
      try {
        const res = await GetRequest('ddl_cities');
        if(res.data){
          let _countries :any= [
            ...new Map(res.data.map((item:any) => [item["country"], item])).values(),
        ];
          setCountries(_countries);
        }
        
      } catch (error) {
        
      }
    };
    request();

  }, [])
  function initGeolocation()
  {

     if( navigator.geolocation )
     {
        // Call getCurrentPosition with success and failure callbacks
        navigator.geolocation.getCurrentPosition( success, function(e){
          console.log(e);
        } );
     }
     else
     {
        alert("Sorry, your browser does not support location services.");
     }
  }

  function success(position:any)
  {
      setLocation({
        long: position.coords.longitude, latt: position.coords.latitude
      })
      console.log(position.coords.longitude);
      console.log(position.coords.latitude);
  }
  const handleFiles = (name:string,file:any) => {
    setfiles({
      ...files,
      [name]: file
    })
  }
  const removeFile = (name:string) => {
    let _files = files;
    delete _files[name];
    setfiles(_files);
  }
  const onDrop = useCallback((acceptedFiles) => {
   let _file = [acceptedFiles[acceptedFiles.length-1]].map((file:any) => {
      const reader = new FileReader();
      reader.onload = function (e:any) {        
        setImage({
          src: e.target.result,
        });
      };
      reader.readAsDataURL(file);
      
      return file;
    });
    console.log('File', _file);
    setImageFile([_file[0]]);
  }, []);
  // useEffect(() => {
  //   console.log(image);
    
  // }, [image])
  const removeImg = () => {
    setImage({src: ''});
    setImageFile([]);
  }
  return (
    <AuthSharedLayout>
      <Box className={gs.responsiveContainer}>
      <Snackbar open={Error} onClose={setError} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} autoHideDuration={3000} anchorOrigin={{vertical:'top', horizontal :'center'}}>
            <Alert severity="error" style={{fontSize: '16px'}} >
              {
                Object.keys(Msg?.errors)[0] === 'email' ? language.id === 1 ? 'البريد الالكتروني مستخدم بالفعل!' : Msg?.errors.email[0]
                : Object.keys(Msg?.errors)[0] === 'username' ? language.id === 1 ? 'اسم المستخدم موجود بالفعل!' : Msg?.errors.username[0]
                : Object.keys(Msg?.errors)[0] === 'phone' ? language.id === 1 ? 'رقم الهاتف مستخدم بالفعل!' : Msg?.errors.phone[0]
                : 'Unexpected Error Happened!'
              }
            </Alert>
        </Snackbar>
      <Snackbar open={ErrorValid} onClose={setErrorValid} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} autoHideDuration={3000} anchorOrigin={{vertical:'top', horizontal :'center'}}>
            <Alert severity="error" style={{fontSize: '16px'}} >
              {ValidMsg}
            </Alert>
        </Snackbar>
        <Box py={4} />
        <form onSubmit={handleSubmit(onSubmit)}  >
        <Typography mb={2} variant='h4'  style={{direction: language.id === 1? 'rtl': 'ltr'}} >
          {language.id === 1? 'انشاء حساب': <>Sign <b>Up</b></>}
        </Typography>
        <Grid container spacing={2}  style={{direction: language.id === 1? 'rtl': 'ltr'}} >
          <Grid item>
            <Link
              href={siteMap.auth.signUp.personal}
              variant='h6'
              fontWeight={type === 'personal' ? 'bold' : 'light'}
              underline='hover'
            >
              {language.id === 1? 'حساب شخصي':'Personal Account'}
            </Link>
          </Grid>

          <Grid item>
            <Typography variant='h6'>|</Typography>
          </Grid>

          <Grid item>
            <Link
              href={siteMap.auth.signUp.business}
              variant='h6'
              fontWeight={type === 'business' ? 'bold' : 'light'}
              underline='hover'
              >
              {language.id === 1? 'حساب اعمال':'Business Account'}
            </Link>
          </Grid>
        </Grid>
        <Box py={3} />
        <Stack spacing={3}>
          <div style={{display: 'flex',direction: language.id === 1? 'rtl': 'ltr'}}>
          <ImagesDropzone
            label={language.id === 1? 'ارفق صورة الملف شخصي':'Upload Profile Photo'}
            onDrop={onDrop} accept={"image/*"}
            {...register('profilePhoto')}
          />
            {/* @ts-ignore */}
            {image.src && <Image width='250' height='150' style={{width: '90%', height: '90%', margin: 'auto auto', borderRadius: '10px'}} alt='profile_iamge' src={image.src} />}
            {image.src && <CancelOutlined style={{cursor: 'pointer'}} onClick={removeImg} />}
          </div>
          <TextField label={language.id === 1? 'اسم المستخدم':'Username'} id='the-user-name' required={true} {...register('username')}  
                  inputProps={{
          // autocomplete: 'username',
          form: {
            autocomplete: 'off',
          },
        }}
        />
          <TextField label={language.id === 1? 'الأسم الأول':'First Name'} required id='first_name' {...register('first_name')} />
          <TextField label={language.id === 1?'الأسم الأخير':'Last Name'} required id='last_name'{...register('last_name')} />
          <TextField placeholder='Password must be at least 8 characters' label={language.id === 1? 'كلمة المرور': 'Password'}
          type={showPassword ? "text" : "password"}
          autoComplete='off'
          id='user-pass'
          InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
          {...register('password')} />
          {InValids.pass && <span style={{color: 'red', fontSize: '14px',margin: '3px 0 0'}} >Password's Minumum Length 8 Characters</span>}
          <TextField
            label={language.id === 1?'تاكيد كلمة المرور':'Confirm Password'}
            type={showPassword ? "text" : "password"}
            placeholder='Password must be at least 8 characters'
            id='re-pass'
            InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                {...register('password_confirmation')}
                />
          {InValids.repass && <span style={{color: 'red',fontSize: '14px', margin: '3px 0 0'}} >Password's Minumum Length 8 Characters</span>}
          {InValids.match && <span style={{color: 'red',fontSize: '14px', margin: '3px 0 0'}} >Passwords Not Match</span>}
          <TextField label={language.id === 1? 'البريد الالكتروني':'Email Address'} required id='email' {...register('email')}
                          // inputProps={{
                          //   autocomplete: 'email',
                          //   form: {
                          //     autocomplete: 'off',
                          //   },
                          // }}
          />
          {/* <TextField label={language.id === 1? 'رقم الهاتف':'Mobile Number'} {...register('phone')} /> */}
          {/* @ts-ignore */}
          <MuiPhoneNumber defaultCountry={CountryCode || 'us'}
              required={true}
              id='custom-phone'
              onChange={(num:any)=> {
                setValue('phone', num);
              }}
           label={language.id === 1? 'رقم الهاتف':'Mobile Number'} name='phone' />
           {language.id === 2 ? <span style={{color: 'red',fontSize: '14px', margin: '3px 0 0'}} >Phone number required</span> : <span style={{color: 'red',fontSize: '14px', margin: '3px 0 0'}} >رقم الهاتف مطلوب*</span>}
           {/* @ts-ignore */}
           <h5  style={{direction: language.id === 1? 'rtl': 'ltr'}} >{language.id === 1?'تاريخ الميلاد':'Birth Date'}</h5>
          <DatePicker
            label=''
            // type='date'
            // placeholder=' '
            // aria-placeholder=' '
            value={_bDate}
            onChange={(date:any)=> {
              set_bDate(date);
              setValue('birth', date)
            }}
            renderInput={(params) => <TextField {...params} id="bDate" required />}
            
          />
          <TextField label={language.id === 1?'الجنس':'Gender'} id='gender' required select {...register('gender')}>
            <MenuItem value='male'>{language.id === 1?'ذكر':'Male'}</MenuItem>
            <MenuItem value='female'>{language.id === 1?'أنثى':'Female'}</MenuItem>
            <MenuItem value='other'>{language.id === 1? 'غير ذلك':'Other'}</MenuItem>
          </TextField>
          {type === 'business' ? (
            <>
              <div>
                <Typography  style={{direction: language.id === 1? 'rtl': 'ltr'}}  >
                  {language.id === 1? 'لحسابات الشركات نزل':'For Corporate Accounts download the'}{' '}
                  <Link href={File || '#'} target='_blank' fontWeight={500}>
                    {language.id === 1?'استمارة التسجيل':'Registration form'}
                  </Link>
                  , {language.id === 1? 'املأها, ثم ارفق الملف':'fill it, and upload it back'}
                </Typography>
                <Box mt={2}  style={{direction: language.id === 1? 'rtl': 'ltr'}}  >
                  <FileUploader 
                      onChange={handleFiles} 
                      onRemove={removeFile} 
                      files={files} 
                      name={'registration_form_file'} 
                      label={language.id === 1? 'ارفق استمارة التسجيل':'Upload Registration Form'} />
                </Box>
              </div>
              <TextField label={language.id === 1?'اسم الشركة':'Company Name'} {...register('company_name')} />
              <TextField label={language.id === 1?'المنصب':'Position'} {...register('position')} />
            </>
          ) : null}
          <div>
            <Typography  style={{direction: language.id === 1? 'rtl': 'ltr'}}  fontWeight={500}>{language.id === 1?'العنوان':'Address'}</Typography>
            <Typography  style={{direction: language.id === 1? 'rtl': 'ltr'}}  >{language.id === 1? 'الموقع الدقيق سيساعد بالوصول اليك بشكل أسرع':'Precise location will help reach you faster'}</Typography>
            <Box sx={{ height: 250, width: '100%', mt: 2 }}>
              <GoogleMapComponent
                center={{
                  lat: Location.latt,
                  lng : Location.long,
                }}
                zoom={15}
                // {...register('location')}
              />
            </Box>
          </div>
          <Box display='flex' justifyContent='space-between'  style={{direction: language.id === 1? 'rtl': 'ltr'}} >
            <Button variant='contained' color='primary' onClick={initGeolocation} >
              {language.id === 1?'تحديد موقعي':'Set Location'}
            </Button>
          </Box>

          {/* <TextField label='Map Location' {...register('mapLocation')} /> */}

          <TextField label={language.id === 1?' العملة':' Unit Type'} select {...register('unit_type_id')}>
            <MenuItem value=''>{language.id === 1?'اختر':'Select...'}</MenuItem>
            {
              UnitTypes.map((u:any) => <MenuItem key={u.id} value={u.id}>{u.name.toUpperCase()}</MenuItem> )
            }
          </TextField>

          <TextField required label={language.id === 1?'الدولة':'Country'} select {...register('country')}>
          <MenuItem value=''>{language.id === 1?'اختر':'Select...'}</MenuItem>
          {
              Countries.map((u:any) => <MenuItem key={u.id} value={u.country}>{u.country}</MenuItem> )
            }
          </TextField>
          <TextField required label={language.id === 1?'المدينة':'City' }{...register('city')} />

          <TextField label={language.id === 1?'الجادة':'Avenue'} {...register('avenue')} />
          <TextField label={language.id === 1?'الشارع':'Street'} {...register('street')} />
          <TextField label={language.id === 1?'الحي':'Block'} {...register('block')} />
          <TextField label={language.id === 1?'رقم المنزل':'House Number'} {...register('house_number')} />
          <TextField
            label={language.id === 1? 'المكان بالتحديد':'Special Directions'}
            {...register('special_directions')}
          />

          {type === 'business' ? (
            <>
              <FormControl component='fieldset'  style={{direction: language.id === 1? 'rtl': 'ltr'}} >
                <FormLabel component='legend'>{language.id === 1?'طريقة الدفع':'Payment Method'}</FormLabel>
                <RadioGroup
                  row
                  aria-label='gender'
                  name='payment-method'
                  value={paymentMethod}
                  onChange={({ target: { value } }) => setPaymentMethod(value)}
                >
                  <FormControlLabel
                    value=''
                    control={<Radio />}
                    label={language.id === 1?'التحديد لاحقا':'Select Later'}
                  />
                  <FormControlLabel
                    value='credit'
                    control={<Radio />}
                    label={language.id === 1? 'فتح حساب':'Open Account'}
                  />
                </RadioGroup>
              </FormControl>
              {paymentMethod === 'credit' ? (
                <>
                  <TextField label={language.id === 1?'حد السحب على الحساب':'Account Limit'} {...register('card_limit')} />
                </>
              ) : null}
                  <div  style={{direction: language.id === 1? 'rtl': 'ltr'}}  > 
                    <Grid container spacing={1}>
                      {[
                        {name: 'copy_of_the_commercial_register_file',label:language.id === 1? 'تحميل نسخة من السجل التجاري':'Upload a copy of the commercial register'},
                        {name: 'copy_of_the_commercial_license_file',label:language.id === 1? 'قم بتحميل نسخة من الرخصة التجارية':'Upload a copy of the commercial license'},
                        {name: 'copy_of_the_signature_certified_by_the_chamber_of_commercial_file',label:language.id === 1? 'تحميل صورة التوقيع مصدق من غرفة التجارة':'Upload a  copy of the signature certified by the Chamber of Commerce'},
                        {name: 'copy_of_civil_id_of_authorized_signatories_file',label: language.id === 1? 'تحميل نسخة من البطاقة المدنية للمفوضين بالتوقيع':'Upload a Copy of Civil ID of authorized signatories'},
                        {name: 'copy_of_rent_contract_electricity_payment_receipt_file',label: language.id === 1? 'تحميل نسخة من عقد الإيجار / إيصال تسديد الكهرباء':'Upload a Copy of rent Contract / electricity payment receipt'},
                        {name: 'copy_of_the_founding_of_the_company_and_its_amendments_file',label: language.id === 1? 'تحميل صورة من تأسيس الشركة وتعديلاتها':'Upload a  copy of the founding of the company and its amendments'},
                        {name: 'the_security_check_in_the_amount_file',label: language.id === 1? 'تحميل التحقق الأمني على الكمية':'Upload the security check in the amount'},
                        {name: 'promissory_note_in_the_amount_of_kd_file',label: language.id === 1? 'تحميل كمبيالة بمبلغ بالدينار كويتي':'Upload a promissory note in the amount of KD'},
                      ].map(el => (
                        <Grid key={el.name} item>
                          <FileUploader onChange={handleFiles} onRemove={removeFile} files={files} name={el.name} label={el.label} />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                  <TextField
                    label={language.id === 1 ?'تعليقات / ملاحظات':'Comments / Notes'}
                    minRows={8}
                    multiline
                    {...register('notes')}
                  />
            </>
          ) : null}
          <Button
            size='large'
            variant='contained'
            color='primary'
            type='submit'
            fullWidth
            onClick={()=> {
              setTimeout(() => {
                const act = document.activeElement
                console.log('wow? ', act)
                // @ts-ignore
                if(act?.dir){
                  
                }else{
                  if(act?.id){
                    // @ts-ignore
                    if(valids[act?.id]){
                      // @ts-ignore
                      setValidMsg(language.id === 1 ? valids[act.id][1] : valids[act.id][0]);
                      setErrorValid(true);
                    }
                  }else{
                    // @ts-ignore
                    if(valids[act?.name]){
                      // @ts-ignore
                      setValidMsg(language.id === 1 ? valids[act.name][1] : valids[act.name][0]);
                      setErrorValid(true);
                      // @ts-ignore
                    }else if (act?.type === 'submit'){
                      // @ts-ignore
                      let bDate = document.getElementById('bDate');
                      // @ts-ignore
                      console.log('wow? ', bDate.value);
                    }
                  }
                }
              }, 200);
              // @ts-ignore
              let _pass = document.getElementById('user-pass')?.value;
              // @ts-ignore
              let re_pass = document.getElementById('re-pass')?.value;
              // @ts-ignore
              let bDate = document.getElementById('bDate');
              setTimeout(() => {
                window.scrollBy(0, -300)
              }, 900)
              // @ts-ignore
              if(!bDate.value){
                // @ts-ignore
                bDate?.scrollIntoView(true)
              }else{
                // @ts-ignore
                if(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(bDate?.value)){
                  
                }else{
                  bDate?.scrollIntoView(true);
                  setValidMsg(language.id === 1 ? valids['date-valid'][1] : valids['date-valid'][0]);
                  setErrorValid(true);
                  setTimeout(() => {
                    window.scrollBy(0,-200);
                  }, 200);
                }
              }
              if(_pass.length <8){
                setInValids({
                  pass: true
                })
              }
              if(re_pass.length <8){
                if(_pass.length >= 8){
                  setInValids((pre:any) => ({
                    ...pre,
                      pass: false
                  }))
                }
                setInValids((pre:any) => ({
                  ...pre,
                  repass: true
                }))
              } else if(re_pass !== _pass){
                if(_pass.length >= 8){
                  setInValids((pre:any) => ({
                    ...pre,
                      pass: false
                  }))
                }
                setInValids((pre:any) => ({
                  ...pre,
                    repass: false,
                    match: true
                }))
              }else{
                setInValids({})
              }
                            // @ts-ignore
                            // let uName = document.getElementById('the-user-name')?.value;
                            // if(!uName){
                            //   setTimeout(()=> {
                            //     window.scrollTo(0, 0);
                            //   }, 800)
                            // }
                // const act = document.activeElement;
                // setTimeout(()=> {
                //   act?.scrollIntoView(true);
                //   window.scrollBy(0, -200)
                // }, 500)
            }}
            disabled={Loading}
          >
           {Loading  ?
                      language.id === 1? 'جاري التسجيل': <>Registering</>
                      :
                      language.id === 1? 'انشاء حساب': 'Create Account'}
          </Button>
        </Stack>
        </form>
      </Box>
    </AuthSharedLayout>
  );
};
