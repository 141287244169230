import { useGlobalStyles } from '@/styles';
import { Box, Button, Stack, Typography } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router'
import { siteMap } from 'src/site-map';


export const HomeHero = ({content}: any) => {
  const gs = useGlobalStyles();
  const router = useRouter();

  useEffect(() => {
    localStorage.setItem('logo-ref', content.logoImage);
  }, [content])
  const handleGo = (e: any) => {
    e.preventDefault();
    router.push(siteMap.auth.signUp.personal);
  }
  return (
    <Box
      position='relative'
      minHeight='75vh'
      display='flex'
      alignItems='center'
      py={2}
      sx={{
        backgroundImage: `url(${content.sidebarImage})`,
        // backgroundImage: 'url(/assets/images/home/hero/hero.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '#0005',
        boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.7)',
      }}
    >
      <Stack
        className={gs.responsiveContainerM}
        bgcolor='#0000'
        maxWidth={750}
        spacing={3}
      >
        <Typography color='white' variant='h3' fontWeight={500}>
         {content.title}
        </Typography>
        <Typography color='white' variant='h5' fontWeight={500}>
         {content.text}
        </Typography>
        {/* <Typography color='white'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi posuere
          facilisis luctus. Quisque eget nunc eu mauris elementum rhoncus. Nam
          rutrum orci in volutpat fermentum. Nunc dapibus dui erat, a elementum
          augue hendrerit sed. Aenean ac velit a velit euismod mollis vel non
          tellus. Sed a turpis placerat, dictum lorem ac, porta odio.
        </Typography> */}
        <Button
          variant='outlined'
          color='secondary'
          size='large'
          sx={{ alignSelf: 'flex-start' }}
          onClick={handleGo}
        >
          START HERE
        </Button>
      </Stack>
    </Box>
  );
};
