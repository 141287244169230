import React, {useContext, useEffect,useState} from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import useLogStore from 'src/logic/utils/create-store/user-store';
import useCartStore from 'src/logic/utils/create-store/cart-store';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import { LanguageContext } from 'src/ui/components/layout';
import { GoogleMapComponent } from 'src/ui/components/shared';
import MuiPhoneNumber from 'material-ui-phone-number';
import axios from 'axios';

type Props = {
  onNext?: () => void;
  onPrev?: () => void;
};

export const CheckOutShippingInfoPage: React.FC<Props> = ({
  onNext,
  onPrev,
}) => {

  const userStore = useLogStore((state: any) => state);
  const cartStore = useCartStore((state: any) => state);

  const [User, setUser] = useState<any>({});
  const [check, setcheck] = useState<any>(false);
  const [CountryCode, setCountryCode] = useState('us');
  const [Location, setLocation] = useState({
    lng: 49.1231,
    lat: 29.5211
  });
  const [UnitTypes, setUnitTypes] = useState<any>([]);
  const [Cities, setCities] = useState<any>([]);
  const language = useContext(LanguageContext);

  useEffect(() => {}, [language.id])
  useEffect(() => {
    setUser(userStore.profile);
    console.log('inShipping:',userStore.profile);
    
    const request = async () => {
      try {
        const res = await GetRequest('ddl_unit_types');
        setUnitTypes(res.data);
        
      } catch (error) {
        
      }
      try {
        const res = await GetRequest('ddl_cities');
        setCities(res.data);
        
      } catch (error) {
        
      }
    };
    request();

  }, [])
  
  const handleChange = ({target}:any) => {
     const {name, value} = target;
     if(name === 'city_id' && value !== 'empty'){
        cartStore.setDelivery(Cities.find((c:any) => c.id === value));
     }
     setUser({
       ...User,
       [name]: value
     })
  }
  function initGeolocation()
  {
     if( navigator.geolocation )
     {
        // Call getCurrentPosition with success and failure callbacks
        navigator.geolocation.getCurrentPosition( success, function(e){
          console.log(e);
        }, {timeout: 5000} );
     }
     else
     {
        alert("Sorry, your browser does not support location services.");
     }
  }

  function success(position:any)
  {
      setUser({
        ...User,
        address_lat: position.coords.latitude,
        address_lng: position.coords.longitude,
        map_location: `${position.coords.longitude}, ${position.coords.latitude}`
      })
      setLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      })
      console.log(position.coords.longitude);
      console.log(position.coords.latitude);
  }
  const PHONE = () =>  {
    useEffect(() => {
      // Get Country Code
      axios.get('https://ipapi.co/json/').then((response:any) => {
        let data = response.data;      
        setCountryCode(data.country_code.toLocaleLowerCase());
    }).catch((error:any) => {
        console.log(error);
    });
    }, [])
    useEffect(() => {
      // console.log('InPhone ', CountryCode, User)
    }, [CountryCode,User.phone])
    useEffect(() => {
    }, [User])
    
    return <MuiPhoneNumber 
    defaultCountry={CountryCode}
    value={User.phone || ''}
    onChange={(num:any)=> {
      setUser({
        ...User,
        phone: num
      })
    }} label={language.id === 1? 'رقم الهاتف':'Mobile Number'} name='phone' />
  }
  return (
    <Box>
      <Box py={3} />
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xl={5} lg={6} xs={12}>
        <div>
            <Typography fontWeight={500}>Address</Typography>
            <Typography>Precise location will help reach you faster</Typography>
            <Box sx={{ height: 250, width: '100%', mt: 2 }}>
              <GoogleMapComponent
                center={{
                  lat: Location.lat,
                  lng: Location.lng,
                }}
                zoom={15}
                // {...register('location')}
              />
            </Box>
          </div>
          <Box display='flex' justifyContent='space-between' style={{margin: '20px 0'}} >
            <Button variant='contained' color='primary' onClick={initGeolocation} >
              Set Location
            </Button>
          </Box>
          <Stack spacing={2}>
            <Typography variant='h4' pb={1} style={{direction: language.id === 1? 'rtl': 'ltr'}} >
              {language.id === 1? 'عنوان الشحن':<>Shipping <b>Address</b></>}
            </Typography>
            <TextField required value={User.city_id || 'empty'} name='city_id' onChange={handleChange} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} select>
              <MenuItem value='empty'>{language.id === 1? 'المدينة...':'City...'}</MenuItem>
              {
                Cities.map((c: any, i:number) => 
                  <MenuItem key={i} value={c.id}>{c.country} - {c.governorate} , {c.city}</MenuItem>
                )
              }
            </TextField>
            {check && !User.city_id ? <span  style={{color: 'red', margin: '0'}} >City is Required !</span>: ''}
            <TextField value={User.street || ''} name='street' onChange={handleChange} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} placeholder={language.id === 1? 'الشارع':'Street'} />
            {check && !User.street ? <span  style={{color: 'red', margin: '0'}} >Street is Required !</span>: ''}
            <TextField value={User.unit_type_id || 'empty'} name='unit_type_id' onChange={handleChange} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}}  select>
              <MenuItem value='empty'>{language.id === 1 ? 'العملة...' : 'Unit Type...'}</MenuItem>
              {
                UnitTypes.map((ut: any, i:number) => 
                <MenuItem key={i} value={ut.id}>{ut.name}</MenuItem>
                )
              }
            </TextField>
            <TextField value={User.avenue || ''} name='avenue' onChange={handleChange} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} placeholder={language.id === 1 ? 'الجادة':'Avenue'} />
            <TextField value={User.block || ''} name='block' onChange={handleChange} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} placeholder={language.id === 1? 'الحي':'Block'} />
            <TextField value={User.house_number || ''} name='house_number' onChange={handleChange} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} placeholder={language.id === 1?'رقم المنزل':'House Number'} />
            <TextField value={User.special_directions || ''} name='special_directions' onChange={handleChange} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} placeholder={language.id === 1?'المكان بالتحديد':'Special Directions'} />
          </Stack>
        </Grid>
        <Grid item xl={5} lg={6} xs={12}>
          <Stack spacing={2}>
            <Typography variant='h4' pb={1} style={{direction: language.id === 1? 'rtl': 'ltr'}} >
              {language.id === 1 ? 'معلومات التواصل':<><b>Contact</b> Info</>}
            </Typography>
            <TextField style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} name='first_name' onChange={handleChange} placeholder={language.id === 1?'الأسم الأول':'First Name'} value={User.first_name}  />
            <TextField style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} name='last_name' onChange={handleChange} placeholder={language.id === 1?'الأسم الأخير':'Last Name'} value={User.last_name} />
            <TextField style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} name='email' onChange={handleChange} placeholder={language.id === 1?'البريد الالكتروني':'Email'} value={User.email} />
            {/* <TextField style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} name='phone' onChange={handleChange} placeholder={language.id === 1? 'الهاتف':'Phone'} value={User.phone} /> */}
            <PHONE />
          </Stack>
        </Grid>
      </Grid>

      <Box py={1} />
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Button
          variant='text'
          size='large'
          onClick={onPrev}
          startIcon={<KeyboardArrowLeft color='secondary' />}
        >
          {language.id === 1 ? 'سلة المشتريات':'Shopping Cart'}
        </Button>
        <Button
          variant='contained'
          size='large'
          onClick={function(e){
            e.preventDefault();
            setcheck(true)
            if(User.city_id && User.street){
              onNext?.();
              userStore.setProfile(User);
              userStore.setFProfile(User);
            }
          }}
          endIcon={<KeyboardArrowRight color='secondary' />}
        >
          {language.id === 1?'طريقة الدفع':'Payment Method'}
        </Button>
      </Box>
    </Box>
  );
};
