// @ts-nocheck
import create, { GetState, SetState, StoreApi } from 'zustand';
import { devtools, NamedSet, persist } from 'zustand/middleware';
import produce, { Draft } from 'immer';
import pipe from 'ramda/src/pipe';
import { StateCreator, BaseState } from './types';

const immer =
  <T extends BaseState, U extends BaseState>(
    config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void, U>
  ): StateCreator<T, SetState<T>, U> =>
  (set, get, api) =>
    config(fn => set(produce(fn) as (state: T) => T), get, api);

const combine =
  <PrimaryState extends BaseState, SecondaryState extends BaseState>(
    initialState: PrimaryState,
    create: (
      set: SetState<PrimaryState>,
      get: GetState<PrimaryState>,
      api: StoreApi<PrimaryState>
    ) => SecondaryState
  ): StateCreator<PrimaryState & SecondaryState> =>
  (set, get, api) =>
    Object.assign(
      {},
      initialState,
      create(
        set as SetState<PrimaryState>,
        get as GetState<PrimaryState>,
        api as StoreApi<PrimaryState>
      )
    );

const combineAndImmer = <
  PrimaryState extends BaseState,
  SecondaryState extends BaseState
>(
  initialState: PrimaryState,
  config: StateCreator<
    PrimaryState,
    (fn: (draft: Draft<PrimaryState>) => void) => void,
    SecondaryState
  >
): StateCreator<PrimaryState & SecondaryState> => {
  return combine(initialState, immer(config));
};

const withDevtools = (name: string) => (config: (set: NamedSet<object>, get: GetState<object>, api: StoreApi<object>) => object) => devtools(config, name);

const withPersist = (name: string) =>  (config: (set: SetState<object>, get: GetState<object>, api: StoreApi<object>) => object) => persist(config, {name} );

export const createStore = (name: string) =>
  pipe(combineAndImmer, withPersist(name), withDevtools(name), create);

export const createStoreWithOutPersist = (name: string) =>
  pipe(combineAndImmer, withDevtools(name), create);
