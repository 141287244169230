import { SvgIconProps, SvgIcon } from '@material-ui/core';

export const ShopOutlined: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox='0 0 42.945 39.705'>
    <path
      d='M256.085,284.143a1.092,1.092,0,0,0-.064-.147l-4.363-8.3a1.344,1.344,0,0,0-1.146-.692H220.046a1.344,1.344,0,0,0-1.146.692l-4.363,8.3a1.083,1.083,0,0,0-.064.147.8.8,0,0,0-.167.489,8.126,8.126,0,0,0,1.323,4.587,5.115,5.115,0,0,0,1.523,1.48l.066.039v21.868a1.1,1.1,0,0,0,1.1,1.1H252.24a1.1,1.1,0,0,0,1.1-1.1V290.738l.066-.039a5.115,5.115,0,0,0,1.523-1.48,8.126,8.126,0,0,0,1.323-4.587A.8.8,0,0,0,256.085,284.143ZM220.232,276.6h30.094l3.8,7.23H216.433Zm26.308,8.831a6.2,6.2,0,0,1-1,2.876,2.445,2.445,0,0,1-4.387,0,6.2,6.2,0,0,1-1-2.876Zm-8.069,0a6.2,6.2,0,0,1-1,2.876,2.445,2.445,0,0,1-4.387,0,6.2,6.2,0,0,1-1-2.876Zm-8.069,0a6.2,6.2,0,0,1-1,2.876,2.445,2.445,0,0,1-4.387,0,6.2,6.2,0,0,1-1-2.876Zm-13.455,2.876a6.2,6.2,0,0,1-1-2.876h6.385a6.2,6.2,0,0,1-1,2.876,2.445,2.445,0,0,1-4.387,0ZM241.336,312.1H229.222v-9.225A5.786,5.786,0,0,1,235,297.1h.554a5.786,5.786,0,0,1,5.78,5.78V312.1Zm10.4,0h-8.8v-9.225a7.4,7.4,0,0,0-7.381-7.381H235a7.4,7.4,0,0,0-7.381,7.381V312.1h-8.8V291.261c.106.009.213.015.321.015a3.765,3.765,0,0,0,1.989-.577,5.115,5.115,0,0,0,1.523-1.48,6.725,6.725,0,0,0,.523-.9,6.722,6.722,0,0,0,.523.9,5.115,5.115,0,0,0,1.523,1.48,3.715,3.715,0,0,0,3.977,0,5.115,5.115,0,0,0,1.523-1.48,6.722,6.722,0,0,0,.523-.9,6.724,6.724,0,0,0,.523.9,5.115,5.115,0,0,0,1.523,1.48,3.715,3.715,0,0,0,3.977,0,5.114,5.114,0,0,0,1.523-1.48,6.724,6.724,0,0,0,.523-.9,6.724,6.724,0,0,0,.523.9,5.114,5.114,0,0,0,1.523,1.48,3.715,3.715,0,0,0,3.977,0,5.114,5.114,0,0,0,1.523-1.48,6.726,6.726,0,0,0,.523-.9,6.719,6.719,0,0,0,.523.9,5.114,5.114,0,0,0,1.523,1.48,3.764,3.764,0,0,0,1.989.577c.108,0,.215-.005.321-.015V312.1Zm1.873-23.794a3.263,3.263,0,0,1-1.37,1.194l-.02.008a2.1,2.1,0,0,1-.8.163,2.753,2.753,0,0,1-2.194-1.366,6.2,6.2,0,0,1-1-2.876h6.385A6.2,6.2,0,0,1,253.61,288.309Z'
      transform='translate(-213.807 -274.5)'
      stroke='currentColor'
      strokeWidth={1}
    />
  </SvgIcon>
);
