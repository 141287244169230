type Options = {
  hue?: number;
  saturation?: number;
  lightness?: number;
  alpha?: number;
};

export const hsla = {
  fromString: (color: string, options?: Options) => {
    const res = color.match(/\d+/g);
    if (!(res && res.length === 4)) {
      throw Error(`${color} isn't a valid hsla format`);
    }
    const [_hue, _saturation, _lightness, _alpha] = res;
    return hsla.from(_hue, _saturation, _lightness, _alpha, options);
  },
  from: (
    _hue: number | string,
    _saturation: number | string,
    _lightness: number | string,
    _alpha: number | string,
    options?: Options
  ) => {
    let hue = Number(_hue) + Number(options?.hue ?? 0);
    hue = hue > 360 ? 360 : hue < 0 ? 0 : hue;

    let saturation = Number(_saturation) + Number(options?.saturation ?? 0);
    saturation = saturation > 100 ? 100 : saturation < 0 ? 0 : saturation;

    let lightness = Number(_lightness) + Number(options?.lightness ?? 0);
    lightness = lightness > 100 ? 100 : lightness < 0 ? 0 : lightness;

    let alpha = options?.alpha ?? _alpha;
    alpha = alpha > 1 ? 1 : alpha < 0 ? 0 : alpha;

    return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
  },
} as const;
