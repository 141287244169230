import axios from 'axios';
import Routes from './Routes';

const instance = axios.create({baseURL: 'https://api.al-tuhoo.com/api'});

const PostRequest = async (routename, req)=> {
    var response = {};
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'accept': 'application/json'
          }
    }
    try {
        const res = await instance.post(Routes[routename], req,config);
        return res;
    } catch (error) {
        console.log('POST ERROR: ', error)
        if(error.response.data)
           return {data: {success: false, ...error.response.data}}
        else
           return {data: {success: false}}
    }
}

export default PostRequest;