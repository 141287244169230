// @ts-nocheck
import create from "zustand";

const useStore = create((set: any) => ({
      products: [],
      Order: {},
      Discount: {},
      Delivery: {},
      TokePromo: {},
      method: '',
      OrderId: null,
      removeProduct: (id: any) =>
            set((state: any) => ({
                products: state.products.filter((pokemon: any) => pokemon.id !== id),
      })),
      addProduct: (product: any) =>
            set((state: any) => ({
                products: [...state.products, {...product}],
      })),
      updateProduct: (product: any) =>
            set((state: any) => ({
                products: state.products.map((prod):any => product.id === prod.id ? product : prod),
      })),
      emptyCart: () =>
            set((state: any) => ({
                products: [],
      })),
      setTokePromo: (code,payload) =>
            set((state: any) => ({
                  TokePromo: {...state.TokePromo, [code]: payload},
      })),
      removeTokePromo: (id) =>
            set((state: any) => {
                  let _new = state.TokePromo;
                  delete _new[id];
                  return {
                  TokePromo: _new,
      }}),
      emptyTokePromo: () =>
            set((state: any) => ({
                  TokePromo: {},
      })),
      setMethod: (method: any) =>
            set((state: any) => ({
                  method: method,
      })),
      setDiscount: (Discount: any) =>
            set((state: any) => ({
                  Discount: Discount,
      })),
      setDelivery: (Delivery: any) =>
            set((state: any) => ({
                  Delivery: Delivery,
      })),
      setOrder: (Order: any) =>
            set((state: any) => ({
                  Order: Order,
      })),
      setOrderId: (id: any) =>
            set((state: any) => ({
                  OrderId: id,
      })),


}));
export default useStore;