import React, { MutableRefObject } from 'react';

export const mergeRefs = <T>(
  ...refs: (MutableRefObject<T> | React.RefObject<T> | React.ForwardedRef<T>)[]
): React.LegacyRef<T> => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return inst => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        // @ts-ignore
        ref.current = inst;
      }
    }
  };
};
