import React, { forwardRef, useCallback, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Close, CloudUploadOutlined, Visibility } from '@material-ui/icons';
import clsx from 'clsx';
import locale from './locale';
import { useInnerTranslation } from '@scandinavia/use-inner-translation';
import { mergeRefs } from '@/utils';
import Image from 'next/image';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    border: 1,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.divider,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    outline: 'none',
    padding: theme.spacing(3),
    width: 180,
    maxWidth: '100%',
    height: 180,
    '&:hover': {
      backgroundColor: 'action.hover',
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
  gridContainer: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gap: theme.spacing(2),
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
  },
  removeUpload: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  forHover: {
    position: 'relative',
    '&:hover > .to-view': {
      opacity: 1,
    },
    '& > .to-view': {
      transition: '.3s',
      opacity: 0,
    },
  },
  avatar: {
    width: 180,
    height: 180,
  },
  icons: {
    position: 'absolute',
    top: 0,
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
}));

interface FileDropzoneProps extends DropzoneOptions {
  files?: string[];
  onRemove?: (file: string) => void;
  onRemoveAll?: () => void;
  loading?: boolean;
  label?: React.ReactNode;
}

export const ImagesDropzone = forwardRef<HTMLInputElement, FileDropzoneProps>(
  (props, ref) => {
    const {
      files,
      onRemove,
      onRemoveAll,
      loading,
      label,
      ...options
    } = props;

    const classes = useStyles();
    const { getRootProps, getInputProps, isDragActive, rootRef, inputRef } =
      useDropzone(options);

    const [preview, setPreview] = useState<string>();

    const onClose = useCallback(() => setPreview(undefined), []);

    const { t } = useInnerTranslation(locale);

    return (
      <>
        <Box className={classes.gridContainer}>
          <Box
            className={classes.root}
            sx={{
              ...(isDragActive && {
                backgroundColor: 'action.active',
                opacity: 0.5,
              }),
            }}
            ref={rootRef}
            {...getRootProps()}
          >
            <input ref={mergeRefs(inputRef, ref)} {...getInputProps()} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CloudUploadOutlined />
              <Typography variant='body2' pt={2} textAlign='center'>
                {label}
              </Typography>
            </Box>
          </Box>
          {loading ? (
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,.3)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 5,
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
          {files && files.length > 0 && (
            <Box className={classes.gridContainer}>
              <Box className={classes.flexContainer}>
                {files.map((file, i) => (
                  <Box key={i} className={classes.forHover}>
                    <Avatar
                      className={classes.avatar}
                      variant='rounded'
                      alt={file}
                      src={file}
                    />
                    <Tooltip title={t('remove')}>
                      <IconButton
                        className={clsx([classes.icons, 'to-view'])}
                        onClick={() => onRemove && onRemove(file)}
                        sx={{ right: 0 }}
                      >
                        <Close fontSize='small' />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('view')}>
                      <IconButton
                        className={clsx([classes.icons, 'to-view'])}
                        onClick={() => setPreview(file)}
                        sx={{ left: 0 }}
                      >
                        <Visibility fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ))}
              </Box>
              <Box className={classes.removeUpload}>
                <Button
                  color='primary'
                  onClick={onRemoveAll}
                  size='small'
                  type='button'
                  variant='text'
                >
                  {t('remove-all')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Dialog onClose={onClose} open={!!preview}>
          <DialogTitle sx={{ m: 0, p: 0 }}>
            <Box className={classes.dialogTitle}>
              <Typography variant='h6' component='div'>
                {t('preview')}
              </Typography>
              <IconButton
                size='small'
                className={classes.closeButton}
                onClick={onClose}
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers sx={{ position: 'relative' }}>
            {preview && (
              <Image
                src={preview}
                alt='PREVIEW'
                layout='fill'
                objectFit='contain'
              />
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  }
);
