// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ar: {
    'remove-all': 'حذف الكل',
    preview: 'معاينة',
    'select-file': 'اختر ملفاً',
    'drop-file': 'اسحب ملفاً',
    browse: 'تصفح',
    'through-you-machine': 'عبر جهازك',
    remove: 'حذف',
    view: 'معاينة',
  },

  en: {
    'remove-all': 'Remove All',
    preview: 'Preview',
    'select-file': 'Select File{{s}}',
    'drop-file': 'Drop File{{s}}',
    browse: 'browse',
    'through-you-machine': 'thorough your machine',
    remove: 'Remove',
    view: 'View',
  },
};
