import { forwardRef } from 'react';
import PerfectScrollbar, {
  ScrollBarProps as PerfectScrollbarProps,
} from 'react-perfect-scrollbar';
import { Box } from '@material-ui/core';

export type ScrollbarProps = PerfectScrollbarProps;

const isMobile =
  typeof window !== 'undefined' &&
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    window?.navigator?.userAgent
  );

export const Scrollbar = forwardRef<PerfectScrollbar, ScrollbarProps>(
  (props, ref) =>
    isMobile ? (
      <Box ref={ref} sx={{ overflow: 'auto' }}>
        {props.children}
      </Box>
    ) : (
      <PerfectScrollbar ref={ref} {...props} />
    )
);
