import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

interface Props {
  title?: string;
  des?: string;
  icon?: React.ReactNode;
  children?: never;
}

const useStyles = makeStyles(t => ({
  root: {
    padding: t.spacing(3, 4),

    border: `1px solid #0002`,
    transition: '.27s',
    '&:hover': {
      backgroundColor: t.palette.secondary.main,
      transform: 'scale(1.02)',
      boxShadow: t.shadows[2],
    },
  },
}));

export const Tag: React.FC<Props> = ({ des, icon, title }) => {
  const cls = useStyles();
  return (
    <div className={cls.root}>
      <Box display='flex' alignItems='center'>
        <Typography variant='h4'>{icon}</Typography>
        <Box ml={2}>
          <Typography style={{color: 'black'}} fontWeight={500}>{title}</Typography>
          <Typography style={{color: 'black'}} variant='body2'>{des}</Typography>
        </Box>
      </Box>
    </div>
  );
};
