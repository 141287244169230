import { Box, IconButton, Popover, Stack, Typography } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { Link } from '../../../../shared';
import { exist } from '@/utils';
import { Builder, makeConfig } from '@scandinavia/material-form-builder';
import * as yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { Facebook, Google } from '@material-ui/icons';
import { siteMap } from 'src/site-map';
import { LanguageContext } from '../../../main-layout';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';

type Inputs = {
  email: string;
  password: string;
};

type Props = {
  anchorEl?: any | undefined;
  onClose?: () => void;
  Msg?: any;
  loginErr: boolean;
  onLogin: (data: Inputs) => Promise<void> | ((data: Inputs) => void);
};

export const LoginPopover: React.FC<Props> = ({
  anchorEl,
  onClose,
  onLogin,
  loginErr,
  Msg
}) => {
  const language = useContext(LanguageContext);
  const loginConfig = makeConfig<Inputs>()({
   Email: {
     controller: 'text',
     label: language.id === 2 ? '': '',
     name: 'email',
     schema: yup.string().max(255).required(),
   },
   Password: {
     controller: 'password',
     label: language.id === 2 ? '': '',
     name: 'password',
     schema: yup.string().min(6).max(255).required(),
   },
  });
  useEffect(() => {
    
  }, [language.id]);

  const handleGoogle = async  () => {
      const query = [
        ['provider', 'google']
      ]
      const res = await GetRequest('login_via_provider', query);
      localStorage.setItem('social-link', res.data);
      window.open(res.data,'_blank');
    }   
  const handleFace = async  () => {
      const query = [
        ['provider', 'facebook']
      ]
      const res = await GetRequest('login_via_provider', query);
      localStorage.setItem('social-link', res.data);
      window.open(res.data,'_blank');
    }   

  return (
    <Popover
      open={exist(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{ sx: { p: 2, width: 350, maxWidth: '99vw' } }}
    >
      <Builder config={loginConfig} onSubmit={() => onLogin}>
        {({ fields, buttonProps }) => (
          <Stack spacing={2}>
            <fields.Email placeholder={language.id === 2 ? 'Email Address': 'البريد الالكتروني'} style={{direction: language.id === 1?'rtl': 'ltr'}}  />
            <fields.Password placeholder={language.id === 2 ? 'Password': 'كلمة المرور'} style={{direction: language.id === 1?'rtl': 'ltr'}} />
            <Typography variant='body2' color='red' alignSelf='center'>
              {loginErr ? language.id === 1 ? '! البيانات المدخلة غير صحيحة او الحساب غير مؤكد' : Msg: ''}
            </Typography>
            <Link
              href={siteMap.auth.forgetPassword}
              variant='body2'
              underline='hover'
              sx={{ alignSelf: 'flex-end' }}
            >
              {language.id === 1 ? 'نسيت كلمة السر ؟' :'Forget password?'}
            </Link>
            <LoadingButton
              {...buttonProps}
              variant='contained'
              color='primary'
              size='large'
            >
              {language.id === 1 ? 'تسجيل دخول' :'LOG IN'}
            </LoadingButton>
            <Typography variant='body2' color='black' alignSelf='center'>
              {language.id === 1 ? 'او سجل بواسطة' :'Or sign in using'}
            </Typography>
            <div>
              <Stack direction='row' spacing={2} justifyContent='center'>
                <IconButton onClick={handleFace}>
                  <Facebook />
                </IconButton>
                <IconButton onClick={handleGoogle} >
                  <Google />
                </IconButton>
              </Stack>
            </div>
            <Box alignSelf='center'>
              <Typography variant='body2' sx={{ display: 'inline' }}>
                {language.id === 1? 'ليس لديك حساب؟ ' :`Don't have an account?`}
              </Typography>
              <Link
                variant='body2'
                href={siteMap.auth.signUp.personal}
                underline='hover'
                color='black'
                sx={{ display: 'inline' }}
              >
                <b>{language.id === 1?  'سجل' :'Sign up'}</b>
              </Link>
            </Box>
          </Stack>
        )}
      </Builder>
    </Popover>
  );
};
