import {
  Box,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Stack,
  Typography,
  TextField,
  ButtonBase,
} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import { exist } from '@/utils';
import { useGlobalStyles } from '@/styles';
import { Close, Search } from '@material-ui/icons';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import Image from 'next/image';
import { siteMap } from 'src/site-map';
import Link from 'next/link';
import { LanguageContext } from '../../../main-layout';

type Props = {
  anchorEl?: any | undefined;
  onClose?: () => void;
};

export const SearchDialog: React.FC<Props> = ({ anchorEl, onClose }) => {
  const gs = useGlobalStyles();
  const [SearchWord, setSearchWord] = useState<string>('');
  const [Results, setResults] = useState<any>([]);
  const [Total, setTotal] = useState<number>(0);
  const language = useContext(LanguageContext);
  useEffect(() => {
  }, [language.id])
  useEffect(() => {
      const get = async () => {
        try {    
          const query = [
            ['page', 1],
            ['product_name', SearchWord],
          ]
          const res = await GetRequest('products_by_name',query);
          setTotal(res.data.size);
          delete res.data.size;
          let output = Object.keys(res.data).map((k:any) => res.data[k]);
          const prods = output.slice(0,8);
          setResults(prods);
        } catch (error) {
          
        }
        
      }
      setTimeout(() => {
        SearchWord.length >= 1 && get();
        if(!SearchWord){setResults([])}
      }, 300);
  }, [SearchWord]);

  const handleSearch = async ({target}: any) => {
    const {value} = target;
    setSearchWord(value);
    
  }
  return (
    <Modal open={exist(anchorEl)} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: 40,
          left: 0,
          width: '100%',
          height: 'max-content',
          boxShadow: 24,
          px: 2
        }}
        style={{padding: '20px 100px 40px', height: Results.length ? '50vh': '20vh', overflow: Results.length ?'auto' : 'hidden'}}
        className={gs.responsiveContainer}
      >
        <TextField
          variant='standard'
          fullWidth
          placeholder={language.id  === 1? 'ابحث عن منتجات' :'Search Products....'}
          onChange={handleSearch}
          InputProps={{
            sx: {
              '&::before': {
                border: 'none !important',
              },
            },
            startAdornment: (
              <InputAdornment position='start'>
                <Search color='primary' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton color='primary' onClick={onClose}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ overflowY: Results.length ?'auto' : 'initial', height: 'calc(100% - 32px)' }}>
          <Stack spacing={2} mt={2}>
            {Results.map((el: any) => (
                <ButtonBase sx={{ justifyContent: 'flex-start' }} key={el.uuid}>
                      <Link href={{ pathname: siteMap.products.id(), query: { id: el.product_sap_id } }}  >

                  <Typography
                    component={Stack}
                    color='black'
                    fontWeight='medium'
                    spacing={1}
                    direction='row'
                    >
                    <div style={{display: 'flex',alignItems: 'center',gap: '20px'}} >
                      <Search />
                      <div style={{borderRadius: '50%', overflow: 'hidden'}}>
                      <Image  width='70px' height='70px' src={el.profile_image ? `https://api.al-tuhoo.com${el.profile_image}` :'/assets/images/mock/item.webp'} />
                      </div>
                      <span>{el.product_name}</span>
                    </div>
                  </Typography>
                    </Link>
                </ButtonBase>
              ))}
              {
                !Results.length ?
                 <Typography style={{fontSize: '20px', padding: '10px 8px'}} >
                 {language.id === 1?  'لا يوجد نتائج':' No Results (products)!'}
                </Typography> 
                : Total > 7 ? <Link href={{ pathname: siteMap.products.search(), query: { search: SearchWord } }}  >
                    <Typography style={{fontSize: '17px' ,color: '#3b0a0c !important', textDecoration: 'none', paddingLeft: '65px', cursor: 'pointer'}} >
                      {language.id === 1?  'عرض المزيد من النتائج':'Show More Results...'}
                    </Typography> 
                </Link>: ''
              }
          </Stack>
          {/* <Typography variant='h5' fontWeight='medium' mt={2}>
            Products
          </Typography>
          <Box sx={{ width: 210, maxWidth: '100%' }} mt={2} p={1}>
            <MiniProductCard {...product} image={product.images[0].thumbnail} />
          </Box> */}
        </Box>
      </Paper>
    </Modal>
  );
};
