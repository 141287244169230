import React from 'react';
import { Box, Stack, Typography } from '@material-ui/core';
import { checkOutSteps } from '../stepper';
type Props = {};

export const CheckOutCompletedStepper: React.FC<Props> = ({}) => {
  return (
    <Stack spacing={1} direction='row' justifyContent='space-between'>
      {checkOutSteps.map(el => (
        <div key={el.title[1]}>
          <Item {...el} />
        </div>
      ))}
    </Stack>
  );
};

const Item: React.FC<{ title: string[]; icon: JSX.Element }> = ({
  title,
  icon,
}) => {
  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <Box
        sx={{
          backgroundColor: '#0000000e',
          padding: t => t.spacing(1),
          display: 'flex',
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon}
      </Box>
      <Typography>{title}</Typography>
    </Stack>
  );
};
