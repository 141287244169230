import { Divider, ListItem, Popover,Typography } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { Link } from '../../../../shared';
import { exist } from '@/utils';
import { siteMap } from 'src/site-map';
import { LanguageContext } from '../../../main-layout';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import { useState } from 'react';
import moment from 'moment';
import { CancelRounded } from '@material-ui/icons';
import PostRequest from 'src/logic/utils/create-request/Helpers/PostRequest';
import useLogStore from 'src/logic/utils/create-store/user-store';

type Props = {
  anchorEl?: any | undefined;
  setnCount: any;
  onClose?: () => void;
};

export const NotificationsPopover: React.FC<Props> = ({
  anchorEl,
  onClose,
  setnCount
}) => {
  const language = useContext(LanguageContext);
  const [Content, setContent] = useState<any>([]);
  const [Page, setPage] = useState<any>(1);
  const [Update, setUpdate] = useState<any>(1);
  const [Total, setTotal] = useState<any>(1);
  const token = localStorage.getItem('token');
  const userStore = useLogStore((state: any) => state);

  useEffect(() => {
    const get = async () => {
        const res = await GetRequest('get_events',[
            ['page', Page]
        ]);
        if(res){

          if(res.data){
            let _res = res.data;
          setTotal(_res.size);
          delete _res.size;
          let received = Object.keys(_res).map((k:any) => _res[k])
          let _notifis = Content;
          // @ts-ignore
          _notifis = [..._notifis,...received].sort((a:any,b:any) => new Date(b.created_at) - new Date(a.created_at))
          let _count :any= _notifis.reduce((acc:any,ele:any) => acc+= !ele.show && 1,0);
          _notifis = _notifis.filter((value:any, index:any, self:any) => {
            return self.findIndex((v:any) => v.id === value.id) === index;
          })
          setnCount(_count || 0)
          if(_notifis.find((n:any) => n.type === 'promo' && n.show === 0)){
            const res = await GetRequest('profile');
            userStore.setProfile(res.data); 
          }
          setContent(_notifis);
        }
      }
    };
    token && get();
  
  }, [token,Page,Update]);
  useEffect(() => {
  }, [language.id, Content]);

  useEffect(() => {
      setInterval(() => {
        setPage(1);
        setContent(Content.slice(0,10));
        setUpdate(Date.now())
      }, 20000)
  }, []);
  useEffect(() => {
    let n = Content.reduce((acc:any,ele:any) => acc+= !ele.show && 1,0);
    setnCount(n);
    
  }, [Content])
  const handleRead = async (id: any) => {
    let _notifis:any = Content;
    _notifis = _notifis.map((n:any) => n.event_id !== id ? n : ({...n,show: 1}))
    setContent(_notifis);
    const res = await PostRequest('update_event', {event_id: id, is_remove: false});
    console.log(res)
  }
  const handleRemove = async (id: any) => {
     let _notifis:any = Content;
     _notifis = _notifis.filter((n:any) => n.event_id !== id);
     setContent(_notifis);
     const res = await PostRequest('update_event', {event_id: id, is_remove: true});
     console.log(res)
  }
  const showMore = () => {
    let _page = Page;
    setPage(_page+1)
  }
  return (
    <Popover
      open={exist(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      style={{borderRadius: '7px', maxHeight: '400px'}}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{ sx: { p: 2, width: 400, maxWidth: '99vw' } }}
    >
        <div style={{fontWeight: 'bold', width: '100%', direction: language.id === 1? 'rtl':'ltr'}} >{language.id === 1? 'الاشعارات':'Notifications'}</div> <Divider style={{margin: '10px 0'}}  />
        <div>

        {
          Content.length ? <>{Content.map((c:any) => (
            c.type === 'promo' ? 
            <Link href={{ pathname: siteMap.products.code(), query: { code: c.object_id } }} style={{textDecoration: 'none'}} >
            <ListItem style={{display: 'flex'}}  >
            <div onClick={() => handleRead(c.event_id)} style={{flex: 1}} >
            <Typography variant='h6' style={{fontWeight: c.show ? 'lighter':'bolder',display: 'block'}} >{c.type === 'order' ? `${c.title} #${c.order_id}`:c.title}</Typography> 
            <Typography variant='body1' style={{fontWeight: c.show ? 'lighter':'bolder',color: 'black'}} >{c.text}</Typography> 
            <span style={{fontWeight: c.show ? 'lighter':'bolder',color: '#9b9b9b'}} >{moment(c.created_at).fromNow()}</span>
            </div>
              <div>
                <CancelRounded style={{color: '#e51a1a', opacity: '.8'}} onClick={() => handleRemove(c.event_id)} />
              </div>
        </ListItem>
            </Link>
            :
            <Link href={c.type === 'point' ? siteMap.profile.index: c.type === 'order' ? {
              pathname: siteMap.profile.ordersHistory.slug(),
              query: { slug: c.object_id },
            } : '#'}  style={{textDecoration: 'none'}} >
                <ListItem style={{display: 'flex', cursor: 'pointer'}}  >
                    <div onClick={() => handleRead(c.event_id)} style={{flex: 1}} >
                    <Typography variant='h6' style={{fontWeight: c.show ? 'lighter':'bolder',display: 'block'}} >{c.type === 'order' ? `${c.title} #${c.object_id}`:c.title}</Typography> 
                    <Typography variant='body1' style={{fontWeight: c.show ? 'lighter':'bolder',color: 'black'}} >{c.text}</Typography> 
                    <span style={{fontWeight: c.show ? 'lighter':'bolder',color: '#9b9b9b'}} >{moment(c.created_at).fromNow()}</span>
                    </div>
                      <div>
                        <CancelRounded style={{color: '#e51a1a', opacity: '.8'}} onClick={() => handleRemove(c.event_id)} />
                      </div>
                </ListItem>
            </Link>
            ))}
             {Content.length === Total ? '': <h5 style={{margin: '20px 0 0',textAlign: 'center', cursor: 'pointer'}} onClick={showMore} >{language.id === 1? 'عرض المزيد':'Show More'}</h5>}
             </>: <span >{language.id === 1? 'لا يوجد اشعارات...':'No Notifications...'}</span>
        }
        </div>
    </Popover>
  );
};
