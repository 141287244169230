import { mock as m } from '@/utils';

export type ImageModel = {
  full: string;
  thumbnail: string;
};

export const emptyImage = (): ImageModel => ({
  full: '',
  thumbnail: '',
});

export const mockImage = (
  ...params: Parameters<typeof m.image>
): ImageModel => ({
  full: m.image(...params),
  thumbnail: m.image(
    params[0] ? +(Number(params[0]) / 3).toFixed(0) : undefined,
    params[1] ? +(Number(params[1]) / 3).toFixed(0) : undefined,
    params[2]
  ),
});
