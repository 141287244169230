import { Link } from '@/components';
import { useGlobalStyles } from '@/styles';
import { Box, Stack, Typography } from '@material-ui/core';
import React, {useContext,useEffect} from 'react';
import { siteMap } from 'src/site-map';
import { LanguageContext } from '../main-layout';

interface Props {}

export const BottomNavbar: React.FC<Props> = () => {
  const gs = useGlobalStyles();
  const language = useContext(LanguageContext);
  useEffect(() => {}, [language.id])

  return (
    <div className={gs.responsiveContainer}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexDirection={['column', 'column', 'row']}
      >
        <div>
          <Stack
            justifyContent='center'
            alignItems='center'
            direction={['column', 'column', 'row']}
          >
            {[
              ['',"حول",'About', siteMap.aboutUs],
              ['','التوظيف','Careers', siteMap.careers],
              ['','الأخبار','News', siteMap.news.index],
              ['','تواصل معنا','Contact Us', siteMap.contactUs],
              ['','الشروط والقوانين','Terms And Conditions', siteMap.terms],
              ['','السياسة والخصوصية','Privacy & Policy', siteMap.terms],
            ].map(el => (
              <Link
                key={el[2]}
                href={el[3]}
                mr={[0, 0, 2, 6]}
                mb={[2, 2]}
                style={{color: 'black'}}
                underline='hover'
                fontWeight='medium'
              >
                {el[language.id]}
              </Link>
            ))}
          </Stack>
        </div>
        <div>
          <Typography style={{color: 'black'}}>{language.id === 2 ? '© 2021 AL TUHOO. All Rights Reserved.': '© 2021 جميع الحقوق محفوظة'}</Typography>
        </div>
      </Box>
    </div>
  );
};
