import {
  FacebookFilledCircled,
  TwitterFilledCircled,
} from '@/icons';
import { Button, Grid, IconButton, Stack, Typography } from '@material-ui/core';
import { Instagram, WhatsApp } from '@material-ui/icons';
import { useGlobalStyles } from '@/styles';

import { Link, Logo } from '@/components';
import { siteMap } from 'src/site-map';
import Image from 'next/image';
import React , {useState, useEffect, useContext}from 'react';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import { LanguageContext } from '../main-layout';

interface Props {}

export const MainFooter: React.FC<Props> = () => {
  const gs = useGlobalStyles();

  const [content,setContent] = useState<any>({});
  const language = useContext(LanguageContext);

  useEffect(() => {
    const request = async ()=>{
      let res = await GetRequest('footerSettings');
      let res2 = await GetRequest('get_media');
      if(res && res.success){
        setContent({...res.data, ...res2.data[0]});
      }

    }
    request();
  }, []);
  useEffect(() => {}, [language.id]);
  const [_Logo, setLogo] = useState('')
  useEffect(() => {
    const get = async () => {
      const res = await GetRequest('home');
      setLogo(res.data[0].logoImage)
    }
    get();
  }, [])

  return (
    <div className={gs.responsiveContainer} style={{direction: language.id ===2? 'ltr':'rtl', paddingBottom: '10px'}} >
      <Grid container spacing={4}>
        <Grid item xl xs={12} alignSelf='center'>
          <Link href={siteMap.home} style={{transform: 'scale(1.2)'}} >
            <Logo logo={_Logo} style={{transform: 'scale(1.4)'}} />
          </Link>
        </Grid>
        <Grid item xl md={6} xs={12}>
          <Typography variant='h5' fontWeight='medium' color='primary' mb={1}  >
            {language.id ===2?'About': 'حول'}
          </Typography>
          <Typography fontWeight='light' style={{color: 'black'}} >
            {content.about}
          </Typography>
        </Grid>
        <Grid item xl md={6} xs={12}>
          <Typography variant='h5' fontWeight='medium' color='primary' mb={1}  >
            {language.id ===2?'Contacts': 'تواصل معنا'}
          </Typography>
          <Typography fontWeight='light' mb={0.5}>
            <b style={{color: 'black'}}>{language.id ===2?'Address': ' العنوان '} :</b> <span style={{color: 'black'}}>{content.address}</span> 
          </Typography>
          <Typography fontWeight='light' mb={0.5}>
          <b style={{color: 'black'}}>{language.id ===2?'Telephone': ' هاتف '} :</b>{' '}
            <Link  style={{color: 'black'}} target='__blank' href={`tel:${content.phone}`}>
              {content.phone}
            </Link>
          </Typography>
          <Typography fontWeight='light' mb={0.5}>
          <b style={{color: 'black'}}>{language.id ===2?'Email': ' البريد الالكتروني '} :</b>{' '}
            <Link style={{color: 'black'}} target='__blank' href={`mailto:${content.email}`}>
              {content.email}
            </Link>
          </Typography>
          <div>
            <Stack direction='row' mb={0.5}>
              {(
                [
                  [<Instagram key='linked-in' />, content.instagram],
                  [<TwitterFilledCircled key='twitter' />, content.twitter],
                  [<FacebookFilledCircled key='facebook' />, content.facebook],
                ] as const
              ).map((el, i) => (
                <IconButton
                  key={i}
                  color='primary'
                  component={Link}
                  target='_blank'
                  href={`${el[1]}` || '#'}
                >
                  {el[0]}
                </IconButton>
              ))}
            </Stack>
          </div>
          <Button
            startIcon={<WhatsApp />}
            variant='outlined'
            color='primary'
            fullWidth
          >
            <Link target='__blank' href={`tel:${content.mobile}`}>
              {language.id ===2?'Call Us': 'اتصل بنا'}
            </Link>
          </Button>
        </Grid>
        <Grid item xl xs={12}>
          <Typography variant='h5' fontWeight='medium' color='primary' mb={2}>
              {language.id ===2?'Download The App': 'حمل تطبيقنا'}
          </Typography>
          <Stack direction='row' spacing={1}>
          <Link target='__blank' href={content.android || '#'}>
            <Image
              src='/assets/images/footer/google.svg'
              alt='Google Play'
              layout='fixed'
              width={176.12}
              height={57.61}
              />
              </Link>
          <Link target='__blank' href={content.ios || '#'}>
            <Image
              src='/assets/images/footer/apple.svg'
              alt='Apple Store'
              layout='fixed'
              width={176.12}
              height={57.61}
              />
              </Link>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};
