import React, { useState } from 'react';
import { NextPage } from 'next';
import { Box, SwipeableDrawer, useMediaQuery } from '@material-ui/core';
import { useGlobalStyles } from '@/styles';
import { isIOS } from '@/constants';
import { ProfileMenu } from './profile-menu';
import useStore from 'src/logic/utils/create-store/user-store';

type Props = {
  onUpdate?: () => void;
};

export const ProfileLayout: NextPage<Props> = ({ onUpdate, children }) => {
  const userStore = useStore((state: any) => state);
  const gs = useGlobalStyles();
  const smallScreens = useMediaQuery(t => t.breakpoints.down('lg'));
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    setOpen(userStore.toggle.status);
  }, [userStore.toggle.status])
  

  return (
    <>
      <Box className={gs.responsiveContainer} display='flex' py={10}>
        {smallScreens ? null : (
          <>
            <Box flex='0 0 260px'>
              <ProfileMenu onUpdate={onUpdate} />
            </Box>
            <Box px={6} />
          </>
        )}
        <Box flex='1 0'>{children}</Box>
      </Box>
      <SwipeableDrawer
        open={smallScreens && open}
        anchor='right'
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableBackdropTransition={!isIOS}
        disableDiscovery={isIOS}
      >
        <Box width={250} maxWidth='100%'>
          <ProfileMenu onUpdate={onUpdate} />
        </Box>
      </SwipeableDrawer>
    </>
  );
};
