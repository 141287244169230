import React, {useState} from 'react';
import Image from 'next/image';
import { makeStyles } from '@material-ui/styles';
import { Box, BoxProps } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect } from 'react';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
}));

type Props = {
  children?: never;
  size?: number;
  logo?:any;
} & BoxProps;

export const Logo: React.FC<Props> = ({
  children,
  className,
  size = 1,
  logo,
  ...props
}) => {
  const cls = useStyles();
  const [LOGO, setLOGO] = useState(null);
  useEffect(() => {
    const get = async () => {
      const res = await GetRequest('home');
      setLOGO(res.data[0].logoImage)
    }
    get();
  }, [])
  return (
    <Box
      position='relative'
      width={200 * size}
      height={58.66 * size}
      {...props}
      className={clsx(className)}
    >
     {LOGO && <div className={cls.root}>
        <Image
          src={LOGO || '/assets/images/text-logo-primary.svg'}
          // src='/assets/images/text-logo-primary.svg'
          quality={100}
          layout='fill'
          objectFit='contain'
          alt='Al Tuhoo Logo'
          loading='eager'
        />
      </div>}
    </Box>
  );
};
