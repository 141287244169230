// @ts-nocheck
import axios from 'axios';
import Routes from './Routes';

const instance = axios.create({ baseURL: 'https://api.al-tuhoo.com/api' });
const GetRequest = async (routename: any, queries?: any): any => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'accept': 'application/json'
          }
    }
    let result;
    var url = Routes[routename];
    if (queries && queries.length) {
        url = url + '?'
        queries.forEach((q: any, index: any) => {
            url = url + q[0] + '=' + q[1] + (index !== queries.length - 1 ? '&' : '')
        });
    }
    // console.log('URL', url);
    await instance.get(url, config)
        .then((res) => {
            if (res.status === 200) {
                result = res.data;
            } else {
                result = null;
            }
        })
        .catch((err) => {
            if(err.message === 'Request failed with status code 401'){
                localStorage.setItem('token', '');
                window.location.href = '/';
            }
        })

    return result;
}

export default GetRequest;