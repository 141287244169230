import React, { useContext, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import StepConnector, {
  stepConnectorClasses,
} from '@material-ui/core/StepConnector';
import StepLabel, { stepLabelClasses } from '@material-ui/core/StepLabel';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { CreditCard, DirectionsCar, ShoppingCart } from '@material-ui/icons';
import { Box, Stack, Step, Stepper, Typography } from '@material-ui/core';
import { useGlobalStyles } from '@/styles';
import { LanguageContext } from 'src/ui/components/layout';

const ColorlibConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    height: 0,
    border: 0,
  },
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.active}.${stepLabelClasses.label}`]: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  [`& .${stepLabelClasses.completed}.${stepLabelClasses.label}`]: {
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  [`& .${stepLabelClasses.disabled}.${stepLabelClasses.label}`]: {
    color: 'gray',
    fontWeight: 300,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  styleProps: { completed?: boolean; active?: boolean };
}>(({ theme, styleProps }) => ({
  backgroundColor: '#0000000e',
  zIndex: 1,
  color: 'gray',
  padding: theme.spacing(1),
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(styleProps.active && {
    color: theme.palette.secondary.main,
  }),
  ...(styleProps.completed && {
    color: theme.palette.primary.main,
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;

  return (
    <ColorlibStepIconRoot
      styleProps={{ completed, active }}
      className={className}
    >
      {icon}
    </ColorlibStepIconRoot>
  );
}

export const checkOutSteps = [
  { title: ['سلة المشتريات','Shopping cart'], icon: <ShoppingCart   /> },
  { title: ['معلومات الشحن','Shipping Info'], icon: <DirectionsCar   /> },
  { title: ['بيانات الدفع','Payment Method'], icon: <CreditCard   /> },
];

type Props = {
  step: number;
};

export const CheckoutLayout: React.FC<Props> = ({ children, step }) => {
  const gs = useGlobalStyles();
  const language = useContext(LanguageContext);
  useEffect(() => {}, [language.id])
  return (
    <Box className={gs.responsiveContainer} py={6}>
      <Typography variant='h4' fontWeight='medium' style={{direction: language.id === 1? 'rtl': 'ltr'}} >
        {language.id === 1 ? 'الدفع':'Checkout'}
      </Typography>
      <Box py={2} />
      <Stack sx={{ width: '100%' }} spacing={4} mt={2}>
        <Stepper
          activeStep={step}
          connector={<ColorlibConnector />}
          sx={{
            border: '1px solid #0001',
            borderRightWidth: 0,
            borderLeftWidth: 0,
            py: 1,
          }}
        >
          {checkOutSteps.map(({ title, icon }) => (
            <Step key={title[1]} style={{direction: language.id === 1? 'rtl': 'ltr'}} >
              <StyledStepLabel icon={icon} StepIconComponent={ColorlibStepIcon} >
                <span style={{margin: '0 5px'}}  >{language.id === 1 ? title[0] : title[1]}</span>
              </StyledStepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      {children}
    </Box>
  );
};
