type SiteMap = {
  readonly [key: string]: string | Function | SiteMap;
};

const makeSiteMap = <T extends SiteMap>(siteMap: T): T => siteMap;

export const siteMap = makeSiteMap({
  home: '/',
  aboutUs: '/about-us',
  terms: '/terms-and-conditions',
  auth: {
    forgetPassword: '/auth/forget-password',
    login: '/auth/login',
    signUp: {
      personal: '/auth/sign-up/personal',
      business: '/auth/sign-up/business',
    },
  },
  businesses: {
    slug: (slug = '[slug]') => `/businesses/${slug}`,
  },
  careers: '/careers',
  checkOut: {
    index: '/check-out',
    result: '/check-out/result',
  },
  contactUs: '/contact-us',
  news: {
    index: '/news',
    id: (id = '[id]') => `/news/${id}`,
  },
  posts: {
    index: '/posts',
    id: (id = '[id]') => {
     console.log('PostId', id);
     return `/posts/${id}`
    },
  },
  products: {
    index: '/products',
    category: (category = '[category]') => `/products/category/${category}`,
    search: (search = '[search]') => `/products/search/${search}`,
    code: (code = '[code]') => `/products/promo_code/${code}`,
    id: (id = '[id]') => `/products/${id}`,
  },
  profile: {
    index: '/profile/account',
    logout: '/profile/logout',
    account: {
      index: '/profile/account',
      changePassword: '/profile/account/change-password',
    },
    ordersHistory: {
      index: '/profile/orders-history',
      slug: (slug = '[slug]') => `/profile/orders-history/${slug}`,
    },
    favorites: '/profile/favorites',
  },
});
