// import { mockProduct } from '@/models';
// import { mock as m } from '@/utils';
import { useGlobalStyles } from '@/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import React , {useState, useEffect, useContext}from 'react';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import { siteMap } from 'src/site-map';
import { ProductCard } from 'src/ui/components/dumb';
import { LanguageContext } from 'src/ui/components/layout';
import { Link } from 'src/ui/components/shared';
interface Props { 
  rand?:any
  pd?:any
}

export const HomeFeaturedProducts: React.FC<Props> = ({rand, pd}) => {
  const gs = useGlobalStyles();
  const language = useContext(LanguageContext);

  const [content, setContent] = useState<any>([]);
  useEffect(() => {
    const request = async ()=>{
      let query = [
        ['page',1]
      ]
      let res = await GetRequest('featured_products', query);
      delete res.data.size;
      let data = Object.keys(res.data).map((k:any) => res.data[k]);
      if(rand){
          setContent(data.slice(0,4));
      }else{
          setContent(data.slice(0,4));
      }
    }
    request();
  }, [])
  useEffect(() => {

  }, [language.id])

  return (
    <Box className={gs.responsiveContainer} style={{padding: pd ? 0:'auto'}} >
      <Typography variant='h4' mb={2} style={{textAlign: language.id === 1 && !pd? 'end':'start'}} >
       {language.id === 1 ? 'منتجات مميزة': <><b>Featured</b> Products</>}
      </Typography>
      <Grid container spacing={2}>
        {content.map((el:any) => (
          <Grid item key={el.id} lg={3} md={3} xs={12} style={{paddingLeft: '0', transform: 'scale(.83)'}} >
            <Link href={{ pathname: siteMap.products.id(), query: { id: el.product_sap_id } }} underline='none'>
              <ProductCard {...el} title={el.description} overview={el.additional_specifications} image={el.profile_image && `https://api.al-tuhoo.com${el.profile_image}`} />
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
