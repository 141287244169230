import { Box, Typography } from '@material-ui/core';
import Image from 'next/image';
import React from 'react';

interface Props {
  title?: string;
  src: string;
  children?: never;
}

export const CategoryCard: React.FC<Props> = ({ title, src }) => {
  return (
    <Box
      sx={{
        transition: '.27s',
        backgroundColor: '#00000009',
        '&:hover': {
          backgroundColor: t => t.palette.secondary.main,
          transform: 'scale(1.02)',
          boxShadow: t => t.shadows[4],
        },
      }}
    >
      <Box position='relative' height={270}>
        <Image
          src={src ? src : '/assets/images/mock/category.jpg'}
          alt={title}
          layout='fill'
          objectFit='cover'
          loading='lazy'
        />
      </Box>
      <Typography p={2} variant='h5' style={{height: '110px'}} >
        {title}
      </Typography>
    </Box>
  );
};
