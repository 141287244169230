// @ts-nocheck
import create from "zustand";

const useStore = create((set: any) => ({
      user: {
          login: true,
      },
      profile: {},
      fullProfile: {},
      toggle: {
        status: false
      },
      setToggle: (data: any) =>
            set((state: any) => ({
                toggle: data,
      })),
      setProfile: (data: any) =>
            set((state: any) => ({
                profile: data,
      })),
      setFProfile: (data: any) =>
            set((state: any) => ({
                fullProfile: data,
      })),
      setLogIn: () =>
            set((state: any) => ({
                user: {login: true},
      })),
      setLogOut: () =>
            set((state: any) => ({
                user: {login: false},
      })),


}));
export default useStore;