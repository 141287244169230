import {
  AppBar,
  Badge,
  Box,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
} from '@material-ui/core';
import {
  Announcement,
  Apartment,
  ContactPhone,
  FilterFrames,
  Home,
  Info,
  LogoutOutlined,
  Menu,
  Notifications,
  Person,
  ShoppingCart,
  Star,
} from '@material-ui/icons';
import React from 'react';
import { Logo } from 'src/ui/components';
import { useRouter } from 'next/router';
import { hsla } from '@/utils';
import _ListItemButton from '@material-ui/core/ListItemButton';
import { styled } from '@material-ui/styles';
import { isIOS } from '@/constants';
import { Link } from '../../shared';
import { siteMap } from 'src/site-map';
import { NotificationsPopover } from '../desktop-navbar/menus/notifications';
import { LoginPopover } from '../desktop-navbar/menus';
import PostRequest from 'src/logic/utils/create-request/Helpers/PostRequest';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import useLogStore from 'src/logic/utils/create-store/user-store';
import useStore from 'src/logic/utils/create-store/cart-store';

const ListItemButton = styled(_ListItemButton)(({ theme }) => ({
  transition: '.3s',
  '&.Mui-selected': {
    borderLeft: `5px solid ${theme.palette.primary.light}`,
    backgroundColor: hsla.fromString(theme.palette.primary.light, {
      alpha: 0.3,
    }),
    '&:hover': {
      backgroundColor: hsla.fromString(theme.palette.primary.dark, {
        alpha: 0.3,
      }),
    },
    '& > .MuiListItemText-root > .MuiListItemText-primary': {
      fontWeight: 500,
    },
  },
  '& > .MuiListItemIcon-root': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: hsla.fromString(theme.palette.primary.main, {
      alpha: 0.3,
    }),
  },
}));

type NavItem = {
  title: string;
  href: string;
  icon: React.ReactNode;
  protect?: any
  logout?: any
};

const baseNav: NavItem[] = [
  {
    title: 'Profile',
    href: siteMap.profile.account.index,
    icon: <Person />,
    protect: true,
  },
  {
    title: 'Favorites',
    href: siteMap.profile.favorites,
    icon: <Star />,
    protect: true,
  },
  {
    title: 'Orders',
    href: siteMap.profile.ordersHistory.index,
    icon: <FilterFrames />,
    protect: true,
  },
  {
    title: 'Home',
    href: siteMap.home,
    icon: <Home />,
  },
  {
    title: 'About',
    href: siteMap.aboutUs,
    icon: <Info />,
  },
  // {
  //   title: 'Business',
  //   href: '/business',
  //   icon: <BusinessCenter />,
  // },
  {
    title: 'Careers',
    href: siteMap.careers,
    icon: <Apartment />,
  },
  {
    title: 'News',
    href: siteMap.news.index,
    icon: <Announcement />,
  },
  {
    title: 'Contact Us',
    href: siteMap.contactUs,
    icon: <ContactPhone />,
  },
  {
    title: 'Logout',
    href: siteMap.profile.logout,
    icon: <LogoutOutlined />,
    protect: true,
    logout: true,
  },
];

export const MobileNavbar = ({logo}: any) => {
  const [open, setOpen] = React.useState(false);
  const { pathname } = useRouter();
  const token = localStorage.getItem('token');
  const [, setUser] = React.useState<any>({username: '-'});
  const [loginErr, setLoginErr] = React.useState<any>(false);
  const [Msg, setMsg] = React.useState<any>('');
  const [nNotify, setnNotify] = React.useState<any>(0);
  const [anchors, setAnchors] = React.useState<{
    business?: any | undefined;
    login?: any | undefined;
    search?: any | undefined;
    notifications?: any | undefined;
  }>({});
  const userStore = useLogStore((state: any) => state);
  const products = useStore((state: any) => state.products);

  const setAnchor = React.useCallback(
    (key: keyof typeof anchors) =>
      (e: React.MouseEvent<HTMLElement, MouseEvent>) => 
        setAnchors(v => ({ ...v, [key]: e.currentTarget })),
    []
  );

  const delAnchor = React.useCallback(
    (key: keyof typeof anchors) => () =>
      setAnchors(v => ({ ...v, [key]: undefined })),
    []
  );

const handleLogout = () => localStorage.setItem('token', '');
  return (
    <>
      <AppBar color='secondary'>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton
            edge='start'
            color='primary'
            onClick={() => setOpen(true)}
          >
            <Menu />
          </IconButton>
          <div style={{display: 'flex'}} >
          <Link href={siteMap.checkOut.index}>
            <IconButton color='primary'>
              <Badge badgeContent={products.length} color='primary' >
              <ShoppingCart />
              </Badge>
            </IconButton>
          </Link>
          {
            (token) ?<IconButton color='primary' onClick={setAnchor('notifications')} >
                <Badge badgeContent={nNotify} color='primary' >
                  <Notifications  />
                </Badge>
              </IconButton> :''
          }
          {token ? (
          <span></span>
          ) : (
            <IconButton color='primary' id='open-login' onClick={setAnchor('login')}>
              <Person />
            </IconButton>
          )}
          <Link href={siteMap.home} underline='none' style={{marginLeft: '9px'}} >
            <Logo size={0.8} logo={logo} />

          </Link>
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <SwipeableDrawer
        open={open}
        anchor='left'
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableBackdropTransition={!isIOS}
        disableDiscovery={isIOS}
      >
        <Box
          sx={{ width: 250 }}
          role='presentation'
          onClick={() => setOpen(false)}
          onKeyDown={() => setOpen(false)}
        >
          <Link href={siteMap.home} underline='none'>
            <Logo p={2} width='100%' />
          </Link>
          <List>
            {baseNav.map(({ icon, href, title, protect, logout }) => (
              token && protect ?
              <Link href={href} key={href} underline='hover' onClick={logout ? handleLogout : ()=>{}} >
                <ListItemButton selected={pathname === href}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              </Link> : !protect ? <Link href={href} key={href} underline='hover'>
                <ListItemButton selected={pathname === href}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              </Link> : ''
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
      <NotificationsPopover
        anchorEl={anchors.notifications}
        onClose={delAnchor('notifications')}
        setnCount={setnNotify}
      />
      <LoginPopover
        anchorEl={anchors.login}
        loginErr={loginErr}
        Msg={Msg}
        onClose={delAnchor('login')}
        onLogin={async data => {
          const res = await PostRequest('login', data);
          console.log(res.data);
          
          res.data.message && setMsg(res.data.message);          
         if(res.data?.data?.access_token){
           setAnchors({ login: undefined });
           localStorage.setItem('token',res.data.data.access_token);
           const resp = await GetRequest('profile');
           userStore.setProfile(resp.data);
           setUser(resp.data);
           userStore.setLogIn();
           setLoginErr(false);
         }else{
          setLoginErr(true);
         }
        }}
      />
    </>
  );
};
