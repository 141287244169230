import React, { useContext, useEffect } from 'react';
import { Box, IconButton, Stack, Typography } from '@material-ui/core';
import Image from 'next/image';
import { Close } from '@material-ui/icons';
import { LanguageContext } from 'src/ui/components/layout';

type Props = {
  image: string;
  title: string;
  color: string;
  size: string;
  price?: number;
  quantity?: any;
  onClose?: () => void;
  mini?: boolean;
  space?: boolean;
};

export const ProductCell: React.FC<Props> = ({
  color,
  image,
  title,
  size,
  mini,
  // price,
  quantity,
  onClose,
  space
}) => {
  const language = useContext(LanguageContext);
  useEffect(() => {}, [language.id])
  if (mini)
    return (
      <Stack
        height={80}
        minWidth={300}
        alignItems='center'
        direction='row'
        spacing={4}
        style={{marginBottom: space ? '20px' : ''}}
      >
        <Box width={80} height={80} position='relative'>
          <Image layout='fill' objectFit='cover' src={image ? image: '/assets/images/mock/item.webp'} alt={title} />
        </Box>
        <Box py={1}>
          <Typography noWrap maxWidth={300} style={{color: 'black',fontWeight: 'bold'}} >
            {title}
          </Typography>
          <Stack mt={1} direction='row' spacing={1} style={{direction: language.id === 1? 'rtl': 'ltr'}} >
            <Typography variant='body2' style={{color: 'black',margin: '0 4px'}} >
              <b style={{color: 'black'}} >{language.id === 1 ? 'اللون':'Color'}: </b>
              <span style={{color: 'black'}} >{color} </span>
            </Typography>
            <Typography variant='body2' style={{color: 'black',margin: '0 4px'}} >
              <b style={{color: 'black'}} >{language.id === 1? 'الحجم':'Size'}: </b>
              <span style={{color: 'black'}}> {size}</span>
            </Typography>
          </Stack>
          <Typography style={{color: 'black',direction: language.id === 1? 'rtl': 'ltr'}} pt={1}>{language.id === 1? 'الكمية':'Quantity'}: {quantity} </Typography>
        </Box>
      </Stack>
    );

  return (
    <Stack
      height={180}
      minWidth={560}
      alignItems='center'
      direction='row'
      spacing={4}
    >
      <IconButton color='error' onClick={onClose}>
        <Close />
      </IconButton>
      <Box width={170} height={170} position='relative'>
        <Image layout='fill' objectFit='cover' src={image} alt={title} />
      </Box>
      <Box>
        <Typography>{title}</Typography>
        <Stack mt={1} direction='row' spacing={2} style={{direction: language.id === 1? 'rtl': 'ltr'}} >
          <Typography variant='body2'style={{margin: '0 4px'}} >
            <b>{language.id === 1 ? 'اللون':'Color'}: </b>
            {color}
          </Typography>
          <Typography variant='body2'style={{margin: '0 4px'}} >
            <b>{language.id === 1? 'الحجم':'Size'}: </b>
            {size}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};
