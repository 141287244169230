import { hsla } from '@/utils';
import { Box, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import { Link } from 'src/ui/components/shared';

type Props = {
  value?: string | number;
  title?: string | number;
  dark?: boolean;
  link?: string;
};

export const SingleProductKeyValue: React.FC<Props> = memo(
  ({ value, title, dark, link }) => (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      bgcolor={dark ? '#0001' : '#0000'}
      px={2}
      py={0.5}
      sx={{
        transition: t =>
          t.transitions.create('all', {
            duration: t.transitions.duration.shortest,
          }),
        '&:hover': {
          boxShadow: t =>
            `0px 0px 5px 1px ${hsla.fromString(t.palette.primary.main, {
              alpha: 0.5,
            })}`,
        },
      }}
    >
      <Typography variant='h6' style={{color: 'black'}} fontWeight={500}>
        {title}
      </Typography>
      {link ? (
        <Link variant='body1' href={link}>
          {value}
        </Link>
      ) : (
        <Typography variant='body1' style={{color: 'black'}}>{value}</Typography>
      )}
    </Box>
  )
);
