import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Snackbar,
  Radio,
  RadioGroup,
  Stack,
  // TextField,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { useMemo , useEffect } from 'react';
import useCartStore from 'src/logic/utils/create-store/cart-store';
import useUserStore from 'src/logic/utils/create-store/user-store';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import { LanguageContext } from 'src/ui/components/layout';
import { Link } from 'src/ui/components/shared';
import { siteMap } from 'src/site-map';

export const paymentMethods = [
  'cash',
  'cheque',
  'credit-card',
  'account'
] as const;

type PaymentMethods = typeof paymentMethods[number];

type Props = {
  onNext?: () => void;
  onPrev?: () => void;
};

export const CheckOutPaymentMethodPage: React.FC<Props> = ({
  onNext,
  onPrev,
}) => {
  const [paymentMethod, setPaymentMethod] =
    useState<PaymentMethods>('cash');

  const cartStore = useCartStore((state: any) => state);
  const userStore = useUserStore((state: any) => state);
  const language = useContext(LanguageContext);
  useEffect(() => {}, [language.id])
  const [Products, setProducts] = useState<any>([])
  const [Msg, ] = useState<any>()
  const [, ] = useState<any>()
  const [, setCodes] = useState<any>([])
  const [Discount, ] = useState<any>({})
  const [Status, setStatus] = useState<boolean>(true);
  const [Err, setErr] = useState<any>(false);
  const [StatusType, setStatusType] = useState<number>(1);
  const [Agree, setAgree] = useState<boolean>(false);
  const [PointsStatus, setPointsStatus] = useState<any>(1);
  const [TOTAL, setTOTAL] = useState<any>(0);
  useEffect(() => {
    setProducts(cartStore.products);
    let _total = cartStore.products.reduce((acc: any, el: any) => (acc += !el.product.with_points &&   el.product.price_before_promo ? parseFloat((el.product.price_before_promo * el.curQuantity).toFixed(2)) - (el.product.price_before_promo - el.price) : parseFloat((el.price * el.curQuantity).toFixed(2))   ), 0);
    _total += cartStore.Delivery.deleviry_price;
    setTOTAL(_total);
    const request = async () => {
      const res = await GetRequest('pointsConfig');
      console.log('Res', res, userStore.profile);
      setPointsStatus(res.status);
      const res1 = await GetRequest('promo_codes');
      setCodes(res1.data);      
    };
    request();
  }, [])
  useEffect(() => {
    console.log('Stats:', PointsStatus ,userStore.profile);
    
    cartStore.setMethod(paymentMethod);
    let _total = Discount.code ? 
    Discount.is_percentage ? TOTAL -(Discount.amount*TOTAL)/100
    : TOTAL -Discount.amount < 0 ? 0 :TOTAL -Discount.amount
 : TOTAL
    if(paymentMethod === 'account' && parseFloat(_total) > parseFloat(userStore.profile.card_limit)){
      setStatus(true);
      setStatusType(4)
    }else{
      if((PointsStatus !== 1 || userStore.profile.can_point !== 1)){
          
          if(PointsStatus !== 1){
          setStatusType(2)
            }else{
              setStatusType(3)
            }
        setStatus(true);
      }else{
        setStatusType(1)
        if(Agree){
          setStatus(false);
        }else{
          setStatus(true);
  
        }
      }
    }
  }, [paymentMethod, Agree, TOTAL,Discount]);
  const content = useMemo<{ [P in PaymentMethods]: JSX.Element }>(
    () => {
      if(userStore?.profile?.account_type?.id === 2){
        return ({
           'cash': (
             <Typography style={{color: 'black'}} >
               {language.id === 1? 'سيقوم الساعي بتحصيل الأموال عند تسليم الطلب الى مكان اقامتك':'A Courier will collect money at your doorsteps at same time the order is delivered to your place'}
             </Typography>
           ),
           'cheque': (
             <Typography>
             </Typography>
           ),
           'account': (
            <Typography style={{color: 'black'}} >
           {language.id === 2? <> You have <b>{parseFloat(userStore.profile.card_limit)} KWD</b>  in your account. <br/> </> : <>لديك <b>{parseFloat(userStore.profile.card_limit)} KWD</b> في حسابك</>}
            { StatusType === 4 ? <Alert severity="warning" style={{display: 'flex'}}>{language.id === 1? 'ليس لديك رصيد كافي في حسابك':'You Dont Have Enough Balance In You Account!'}</Alert> : ''}

            </Typography>
           ),
           'credit-card': (
             <Typography style={{display: 'flex', color: 'black'}} >
               {
                 StatusType === 1 ? <Typography>{language.id === 1? 
                     <>لديك <b>{userStore.profile.points} نقاط</b> في محفظتك</>
                   :<>You have <b>{userStore.profile.points} Points</b> in your wallet</>}</Typography>: 
                 StatusType === 2 ? <Alert severity="warning" style={{display: 'flex'}}>{language.id === 1? 'استخدام النقاط معطل حاليا من قبل المشرفين':'Using Points Is Currently Disabled By Admin!'}</Alert>: 
                 StatusType === 3 ? <Alert severity="warning" style={{display: 'flex'}}>{language.id === 1? 'استخدام النقاط معطل حاليا على حسابك':'Using Points Is Currently Disabled In Your Account!'}</Alert>: ''
               }
               
             </Typography>
           ),
         })
      }else{

       return ({
          'cash': (
            <Typography style={{color: 'black'}} >
              {language.id === 1? 'سيقوم الساعي بتحصيل الأموال عند تسليم الطلب الى مكان اقامتك':'A Courier will collect money at your doorsteps at same time the order is delivered to your place'}
            </Typography>
          ),
          'cheque': (
            <Typography>
            </Typography>
          ),
          'account': (
            <Typography style={{color: 'black'}} >
                You have {parseFloat(userStore.profile.card_limit)}
            </Typography>
          ),
          'credit-card': (
            <Typography style={{display: 'flex', color: 'black'}} >
              {
                StatusType === 1 ? <Typography>{language.id === 1? 
                    <>لديك <b>{userStore.profile.points} نقاط</b> في محفظتك</>
                  :<>You have <b>{userStore.profile.points} Points</b> in your wallet</>}</Typography>: 
                StatusType === 2 ? <Alert severity="warning" style={{display: 'flex'}}>{language.id === 1? 'استخدام النقاط معطل حاليا من قبل المشرفين':'Using Points Is Currently Disabled By Admin!'}</Alert>: 
                StatusType === 3 ? <Alert severity="warning" style={{display: 'flex'}}>{language.id === 1? 'استخدام النقاط معطل حاليا على حسابك':'Using Points Is Currently Disabled In Your Account!'}</Alert>: ''
              }
              
            </Typography>
          ),
        })
      }
    }
    ,[StatusType,language.id,userStore]
  );
  const checkChange = ({target}:any) => {
    setAgree(target.checked);
  }

  // const applyCode = (e: any) => {
  //   e.preventDefault();
  //   var pCode = document.getElementById('pCode');
  //   // @ts-ignore
  //   const code = pCode?.value;
  //   let foundCode = Codes.find((c:any) => c.code.toLowerCase() === code.toLowerCase());
  //   console.log("CODE", foundCode);
  //   if(foundCode){ 
  //       if(new Date() > new Date(foundCode.expiry)){
  //         let _msg = language.id === 1 ? 'عذرا ! كود الخصم الذي ادخلته لم يعد صالح': 'Sorry! Entered Promo Code Is Expired';
  //         setMsg(_msg);
  //         setErr(true);
  //       }else{
  //         setDiscount(foundCode);
  //         // @ts-ignore
  //         setP_Code('');
  //         cartStore.setDiscount(foundCode);
  //       }
  //   }else{
  //     let _msg = language.id === 1 ? 'عذرا ! كود الخصم الذي ادخلته غير صالح': 'Sorry! Entered Promo Code Is NOt Valid';
  //     setMsg(_msg);
  //     setErr(true);
  //   }
  // }
  return (
    <Box>
     <Snackbar open={Err} onClose={setErr} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} autoHideDuration={3000} anchorOrigin={{vertical:'top', horizontal :'center'}}>
           <Alert severity="error" style={{fontSize: '16px'}} >
            {Msg}
          </Alert>
        </Snackbar>
      <Box py={3} />
      <Grid container spacing={6} justifyContent='space-between' style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} >
        <Grid item lg={6} xs={12}>
          {content['credit-card']}
          <Typography variant='h4'>
            {language.id === 1? 'معلومات الدفع':<><b>Payment</b> Info</>}
          </Typography>
          <Box py={1} />
          <RadioGroup
            row
            value={paymentMethod}
            onChange={({ target: { value } }) =>
              setPaymentMethod(value as PaymentMethods)
            }
          >
            <FormControlLabel
              value='cash'
              style={{color: 'black'}}
              control={<Radio />}
              label={language.id === 1? 'نقدا عند التوصيل':'Cash On Delivery'}
            />
            <FormControlLabel
            style={{color: 'black'}}
              value='credit-card'
              control={<Radio />}
              label={language.id === 1 ? 'عبر بطاقتي' :'Credit Card'}
            />
            {userStore?.profile?.account_type?.id === 2 && <FormControlLabel
              value='cheque'
              style={{color: 'black'}}
              control={<Radio />}
              label={language.id === 1 ? 'بواسطة شيك' :'Cheque'}
            />}
           {userStore?.profile?.account_type?.id === 2 && <FormControlLabel
              value='account'
              control={<Radio />}
              style={{color: 'black'}}
              label={language.id === 1 ? ' على الحساب' :'On Account'}
            />}
          </RadioGroup>
          <Box py={2} />
          {(paymentMethod === 'cash' || paymentMethod === 'account') && content[paymentMethod]}
          <Box py={2} />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={checkChange} />}
              label={
                <Typography style={{color: 'black'}} >
                 {language.id === 1? 'لقد قرأت وفهمت شروط وأحكام Al Tuhoo وسياسة الخصوصية وسياسة الإرجاع قبل تقديم طلباتي': <>I have read and understand Al Tuhoo{' '}
                  <b>
                    <Link href={siteMap.terms} style={{color: 'black', fontWeight: 'bolder', textDecoration: 'none'}} >
                    Terms and Condition, The privacy policy and return policy
                    </Link>
                  </b>{' '}
                  before placing my orders</>}
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <Box width={400} maxWidth='100%'>
            <Paper sx={{ p: 2 }} elevation={3}>
              <Stack spacing={2}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography style={{color: 'black'}}>{language.id === 1? 'مجموع الأغراض':'Items Total'}</Typography>
                  <Typography style={{color: 'black'}}>{Products.reduce((acc: any, el: any) => (acc += !el.product.with_points &&  el.product.price_before_promo ? parseFloat((el.product.price_before_promo * el.curQuantity).toFixed(2)) : parseFloat((el.price * el.curQuantity).toFixed(2))  ), 0)} KWD</Typography>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Typography style={{color: 'black'}}>{language.id === 1? 'النقاط':'Items Points'}</Typography>
                  <Typography style={{color: 'black'}}>{Products.reduce((acc: any, el: any) => (acc += el.product.with_points && el.product.price_as_points * el.curQuantity), 0)} {language.id === 1? 'نقاط':'Points'}</Typography>
                </Box>
                <Divider />
                <Box display='flex' justifyContent='space-between'>
                  <Typography style={{color: 'black'}}>{language.id === 1? 'رسوم التوصيل':'Delivery Charge'}</Typography>
                  <Typography style={{color: 'black'}}>{cartStore.Delivery.deleviry_price} KWD</Typography>
                </Box>
                {/* <Divider />
                <Box display='flex' justifyContent='space-between'>
                  <Typography fontWeight={500}>Total</Typography>
                  <Typography fontWeight={500}>
                    {Products.reduce((acc: any, el: any) => (acc += el.price * el.curQuantity), 0)} KWD
                  </Typography>
                </Box> */}
                <Divider />
                <Box display='flex' justifyContent='space-between'>
                  <Typography style={{color: 'black'}}>{language.id === 1? 'الخصم':'Discount'}</Typography>
                  <Typography style={{color: 'black'}}>-{
                     Products.reduce((acc: any, el: any) => (acc += el.product.price_before_promo ? el.product.price_before_promo-el.product.price :0), 0) + ' KWD'
                    }</Typography>
                </Box>
                <Box style={{marginLeft: '25px'}} >
                {/* Discount detatils */}
                  {
                    Products.map((p: any) => p.product.price_before_promo ? <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}} >
                        <span style={{maxWidth: '75%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} >{p.product.title}</span>
                        <span>{ p.product.price_before_promo - p.product.price}</span>
                    </div>: '')
                  }
                </Box>
                <Divider />
                <Box display='flex' justifyContent='space-between'>
                  <Typography style={{color: 'black'}} variant='h5' fontWeight={500}>
                    {language.id === 1? 'المجموع الاجمالي':'Grand Total'}
                  </Typography>
                  <Typography style={{color: 'black'}} variant='h5' fontWeight={500}>
                    { Discount.code ? 
                       Discount.is_percentage ? TOTAL -(Discount.amount*TOTAL)/100
                       : TOTAL -Discount.amount < 0 ? 0 :TOTAL -Discount.amount
                    : TOTAL
                    } 
                    KWD
                  </Typography>
                </Box>
                <Box />
                {/* { !Discount.code  && !(cartStore.products.reduce((acc: any, el: any) => (acc += !el.product.with_points && el.price * el.curQuantity), 0) === 0 &&
              cartStore.products.reduce((acc: any, el: any) => (acc += el.product.with_points && el.price * el.curQuantity), 0) !== 0)?<Box>
                  <Typography mb={1}>{language.id === 1? 'استخدم كود الخصم':'Apply Promo Code'}</Typography>
                  <Box display='flex' alignItems='center'>
                  <TextField variant='standard' id='pCode' onChange={(e:any) => {setP_Code(e.target.value)}} value={P_Code || ''} placeholder={language.id === 1? 'كود الخصم':'Promo Code'} fullWidth />
                  <Button
                      variant='text'
                      size='medium'
                      onClick={applyCode}
                    >
                      {language.id === 1? 'استخدام':'Apply'}
                    </Button>
                  </Box>
                </Box> :''} */}
              </Stack>
            </Paper>
            <Box py={2} />
            <Typography textAlign='center'>
              {language.id === 1? 'سيتم إرسال الإيصال عن طريق البريد الإلكتروني إلى عنوانك':'Receipt will be sent by email to your address'}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box py={4} />
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Button
          variant='text'
          size='large'
          onClick={onPrev}
          startIcon={<KeyboardArrowLeft color='secondary' />}
        >
          {language.id === 1? 'معلومات الشحن':'Shipping Info'}
        </Button>
        <Button
          variant='contained'
          size='large'
          onClick={onNext}
          disabled={Status}
          endIcon={<KeyboardArrowRight color='secondary' />}
        >
          {language.id === 1? 'التالي':'Next'}
        </Button>
      </Box>
    </Box>
  );
};
