import {
  Box,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import React , {useContext, useEffect, useMemo, useState}from 'react';
import { NumberCounter } from 'src/ui/components/dumb/number-counter';
import useStore from 'src/logic/utils/create-store/cart-store';
import { LanguageContext } from 'src/ui/components/layout';
import { FacebookRounded, ShareRounded , WhatsApp, Telegram, FileCopy} from '@material-ui/icons';
import useLogStore from 'src/logic/utils/create-store/user-store';

type Props = {
  product: any;
  qntt: any;
  product_sap_id?: any;
};

export const SingleProductSideBar: React.FC<Props> = ({ product ,qntt ,product_sap_id}:any) => {
  const {price,quantity, product_name: title, profile_image: image, uuid, size, color,price_as_points} = product;
  const addProduct = useStore((state: any) => state.addProduct);
  const currentCart = useStore((state: any) => state.products);
  const userStore = useLogStore((state: any) => state);

  const [qnt, setQnt] = useState(1);
  const [Added, setAdded] = useState<boolean>(false);
  const [Copy, setCopy] = useState<any>({});
  const [Promo, setPromo] = useState<any>([]);

  const token = localStorage.getItem('token'); 
  const language = useContext(LanguageContext);
  useEffect(() => {
    try {      
      const _code = userStore.profile.promo_code.filter((c:any) => c.products.includes(product_sap_id));
      if(_code){
        console.log('>Codes: ', _code)
        setPromo(_code);
      }
    } catch (error) {
      console.log('PromoErr:', error)
    }
  }, [userStore.profile])
  
  useEffect(() => {
      // @ts-ignore
      let res = currentCart.find((c:any) => c.uuid === uuid);
      if(res){
        setAdded(true);
      }
  }, [language.id, Added])
  useEffect(() => {
    console.log('Colszie: ', color,size,price_as_points);
    
   const faceRef = document.getElementById('face-ref');
   const whatsRef = document.getElementById('whats-ref');
   const teleRef = document.getElementById('tele-ref');
   const prodLink = window.location.href;
   faceRef?.setAttribute('href', `https://www.facebook.com/sharer/sharer.php?u=${prodLink}`)
   whatsRef?.setAttribute('href', `https://api.whatsapp.com/send?text=${prodLink}`)
   teleRef?.setAttribute('href', `https://telegram.me/share/url?url=${prodLink}`)
  }, [product])

  const curr_product = {
    id: Date.now(),
    price,
    quantity: quantity,
    uuid,
    curQuantity: qnt,
    product_sap_id,
    product: {
      price,
      title,
      price_as_points,
      with_points: false,
      size,
      color,
      images: [{
        thumbnail: image || '/assets/images/mock/item.webp'
      }] 
    }
  }
  const INPUT = useMemo(
    () => ( <TextField
    id="outlined-read-only-input"
    fullWidth
    style={{direction: language.id === 1? 'rtl': 'ltr'}}
    defaultValue={language.id === 1 ? `الحجم: ${product.size?product.size: "غير محدد"} , اللون: ${product.color?product.color: "غير محدد"}` :`Size: ${product.size?product.size: "Not specified"} , Color: ${product.color?product.color: "Not specified"}`}
    value={language.id === 1 ? `الحجم: ${product.size?product.size: "غير محدد"} , اللون: ${product.color?product.color: "غير محدد"}` :`Size: ${product.size?product.size: "Not specified"} , Color: ${product.color?product.color: "Not specified"}`}
    InputProps={{
      readOnly: true,
    }}
  />
  )
  , [language.id, product])
  const handleAdd = (e:any) => {
    e.preventDefault();
    if(!token){
      window.scrollTo(0,0);
      let openLogn = document.getElementById('open-login');
      if(openLogn){
        openLogn.click();
      }
    }else{
      addProduct(curr_product);
      setAdded(true)
    }
  }
  return (
    <Box>
      <Typography variant='h4' style={{color: 'black'}} fontWeight={500}>
        {product.product_name}
      </Typography>
      <Box py={1} />
      <Typography variant='h4' fontWeight={500}>
        {product.price} KWD <br/>
        {price_as_points ? 
            <Typography variant='body1' fontWeight='small'>
              {`${price_as_points} Points`}
            </Typography>
          : ''}
      </Typography>
      <Box py={1} />
      <Typography style={{color: 'black'}} variant='body1' >{language.id===1 ? 'كود الخصم':'Promocode:'}</Typography>
      {Promo.map((p:any) => <Box style={{direction: language.id === 1? 'rtl': 'ltr'}} >
        <Typography onClick={()=> {
          navigator.clipboard.writeText(p.code);
          setCopy((prev:any) => ({...prev, [p.code]: true}));
          setTimeout(() => {
            setCopy((prev:any) => ({...prev, [p.code]: false}));
          }, 3000);
        }} style={{color: 'black',fontWeight: 'bold',cursor: 'pointer'}} variant='body1' ><FileCopy /> &nbsp; {p.code} &nbsp;&nbsp; ({p.is_percentage === 1 ? p.amount+'%': p.amount+' KWD'})</Typography>
        {Copy[p.code] ?<Typography  variant='body1' >{language.id===1 ? 'تم نسخ الكود بنجاح':'Promocode copied to clipboard'}</Typography> : ''}
        </Box>)}
      <Box py={1} />
      <Typography fontWeight='medium' style={{direction: language.id === 1? 'rtl': 'ltr'}} >
              <ShareRounded /> <span style={{color: 'black'}}> {language.id === 1 ?'شارك المنتج': 'Share'}: </span>&nbsp;&nbsp;
              <a id='face-ref' target='_blank' href="#" style={{margin: '0 3px', textDecoration: 'none',color: 'inherit'}} >
              <FacebookRounded /> 
              </a>
              <a id='whats-ref' target='_blank' href="#" style={{margin: '0 3px', textDecoration: 'none',color: 'inherit'}} >
               <WhatsApp /> 
              </a>
              <a id='tele-ref' target='_blank' href="#" style={{margin: '0 3px', textDecoration: 'none',color: 'inherit'}} >
                <Telegram />
              </a>
            </Typography>
      <Box py={2} />
      <Typography style={{color: 'black'}} variant='body1'>{product.overview}</Typography>
      <Box py={2} />
      {INPUT}
      <Box py={2} />
      <Typography style={{direction: language.id === 1? 'rtl': 'ltr'}} >{language.id === 1 ?  'الكمية':'Quantity'}</Typography>
      <NumberCounter 
        quantity={qntt}
        getValue={function change(newValue){
        console.log('Quantity:', newValue)
        setQnt(newValue);
      }} />
      <Box py={2} />
      {
        Added ? 
      <Button size='large' 
      variant='contained'
      // color='default'
      style={{ color: 'white', background: "#7a151be6" }}
      sx={{ color: 'secondary.main' }}
       fullWidth 
      onClick={(e: any) => { e.preventDefault()}}
      >
        {language.id === 1? 'تمت اضافته':'ADDED'}
      </Button>
        :
      <Button size='large' color='primary' variant='contained' fullWidth 
      onClick={handleAdd}
      >
        {language.id === 1? 'اضافة الى السلة':'ADD TO CART'}
      </Button>
      }
    </Box>
  );
};
