// @ts-nocheck
import React, { forwardRef } from 'react';

import GoogleMapReact from 'google-map-react';
type Props = {} & GoogleMapReact.Props;

const Markr = () => {

  return <>
       <img src='/assets/images/mock/marker.png' alt='map-markr' style={{width:'46px',height: '46px'}} />  
  </>
}

export const GoogleMapComponent = forwardRef<GoogleMapReact, Props>(
  (props, ref) => {
   console.log("What? ", props)
  return (
    <GoogleMapReact
      {...props}
      ref={ref}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        console.log('What?', map. maps)
      }}
      bootstrapURLKeys={{
        ...props.bootstrapURLKeys,
        key: 'AIzaSyDIyQUifM2xTvc8r1Z13L1jYWexDvQiBGU',
      }}
    >
      <Markr lat={props?.center?.lat || 52.21551} lng={props?.center?.lng || 52.21551}  />
    </GoogleMapReact>
  )}
);
