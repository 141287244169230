import React, {createContext, useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { MainFooter } from '../footer';
import { BottomNavbar } from '../bottom-navbar';
import { DesktopNavbar } from '../desktop-navbar';
import { Header } from '../header';
import { Box, useMediaQuery } from '@material-ui/core';
import { HideOnScroll
  , SplashScreen
 } from '../../shared';
import { MobileNavbar } from '../mobile-navbar';
import { useEffect } from 'react';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import PostRequest from 'src/logic/utils/create-request/Helpers/PostRequest';

const useStyles = makeStyles(() => ({
  header: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 5,
  },
  main: {},
  footer: {},
}));
export const LanguageContext = createContext({id: 2});
export const MainLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreens = useMediaQuery(theme.breakpoints.down('lg'));
  const [Logo, setLogo] = useState('')
  const [SocialDone, setSocialDone] = useState<any>(false)
  const isSocial :any = localStorage.getItem('social-link');
  useEffect(() => {
    const checkSocial = async () => {
      setSocialDone(true)
      const res = await PostRequest('socialLog', {'url': isSocial});
      console.log('social-link',res);
      if(res.data.success){
        localStorage.setItem('token', res.data.data.access_token);
        localStorage.setItem('social-link', '');
        setSocialDone(false);
      }else{
        localStorage.setItem('social-link', '');
        setSocialDone(false);
      }
    }
    if(isSocial){
      isSocial.length && checkSocial();
    }
    const get = async () => {
      const res = await GetRequest('home');
      setLogo(res.data[0].logoImage)
    }
    get();
  }, [isSocial])

  const [languageId, setlanguageId] = useState(2);
  if(SocialDone) return <SplashScreen />
  return (
    <>
    <LanguageContext.Provider value={{id: languageId}}>
      <HideOnScroll>
        <header className={classes.header}>
          {smallScreens ? (
            <MobileNavbar logo={Logo} />
          ) : (
            <>
              <Header setlanguageId={setlanguageId} />
              <DesktopNavbar logo={Logo} />
            </>
          )}
        </header>
      </HideOnScroll>

      <main className={classes.main}>{children}</main>

      <footer className={classes.footer}>
        <MainFooter />
        <Box my={2} />
        <BottomNavbar />
      </footer>
      </LanguageContext.Provider>
    </>
  );
};
