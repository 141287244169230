import React, { useContext, useMemo, useState } from 'react';
import Image from 'next/image';
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@material-ui/core';
import { mock } from '@/utils';
import { TicketFilledIcon } from '@/icons';
import { ExpandLess, ExpandMore, FacebookRounded, ShareRounded, Telegram, WhatsApp } from '@material-ui/icons';
import { useRouter } from 'next/router';
import { Link } from '../../../shared';
import { siteMap } from 'src/site-map';
import useUserStore from 'src/logic/utils/create-store/user-store';
import useCartStore from 'src/logic/utils/create-store/cart-store';
import { LanguageContext } from '../../main-layout';
import { useEffect } from 'react';
import PostRequest from 'src/logic/utils/create-request/Helpers/PostRequest';

type Props = {
  onUpdate?: () => void;
};
// https://api.al-tuhoo.com/invite/5/android
export const ProfileMenu: React.FC<Props> = ({ onUpdate }) => {
  const { pathname } = useRouter();
  var token = localStorage.getItem('token')
  const [expanded, setExpanded] = useState(false);
  const [change, setchange] = useState(false);
  const userStore = useUserStore((state: any) => state);
  const cartStore = useCartStore((state: any) => state);
  const language = useContext(LanguageContext);
 const router = useRouter();
  useEffect(() => {
    console.log('Updated', userStore.profile);
    
  }, [language.id,change])
  const accountList = useMemo(
    () =>
      (
        [
          [
            'Change Password',
            siteMap.auth.forgetPassword,
            'غير كلمة المرور',
          ],
        ] as const
      ).map(el => (
        <React.Fragment key={el[0]}>
          <Divider />
          <Link href={el[1]} underline='hover'>
            <ListItemButton
              sx={{
                fontWeight: pathname === el[1] ? 'bold' : 'regular',
                pl: 4,
              }}
            >
              {/* <ListItemIcon>{el[2]}</ListItemIcon> */}
              <ListItemText primary={language.id === 1 ? 'غير كلمة المرور' :el[0]} />
            </ListItemButton>
          </Link>
        </React.Fragment>
      )),
    [pathname,language.id]
  );
  const baseList = useMemo(
    () =>
      [        // ['Address', siteMap.profile.address],
        ['Favorites', siteMap.profile.favorites, 'المفضلة'],
        ['Orders History', siteMap.profile.ordersHistory.index, 'الطلبات السابقة'],
        ['Logout', siteMap.home,'تسجيل خروج'],
      ]
        // .map(el => [el[0], '/profile/' + el[1]])
        .map(el => (
          <React.Fragment key={el[0]}>
            <Divider />
            <Link href={el[1]} underline='hover' onClick={function(){ if(el[0] === 'Logout'){ localStorage.setItem('token', ''); userStore.setProfile({}); cartStore.emptyCart(); } }} >
              <ListItemButton
                sx={{
                  fontWeight: pathname === el[1] ? 'bold' : 'regular',
                }}
              >
                <ListItemText primary={language.id === 1 ? el[2]:el[0]} />
              </ListItemButton>
            </Link>
          </React.Fragment>
        )),
    [pathname,language.id,change]
  );
  
  // useEffect(() => {
  //   const faceRef = document.getElementById('ref-face');
  //   faceRef?.setAttribute('href', `https://www.facebook.com/sharer/sharer.php?u=https://play.google.com/store/apps/details?id=com.tuhoo.tuhoo&hl=en_US&gl=US`)
  //   const whatsRef = document.getElementById('ref-whats');
  //   whatsRef?.setAttribute('href', `https://api.whatsapp.com/send?text=https://play.google.com/store/apps/details?id=com.tuhoo.tuhoo&hl=en_US&gl=US`)
  //   const teleRef = document.getElementById('ref-tele');
  //   teleRef?.setAttribute('href', `https://telegram.me/share/url?url=https://play.google.com/store/apps/details?id=com.tuhoo.tuhoo&hl=en_US&gl=US`)
  // }, []);
  const uploadImage = () => {
    let uploader  = document.getElementById('img-uploader');
    uploader?.click();
  }
  const ImageUpload = async (e:any) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append('image', file)
    const res = await PostRequest('profile_image', formData);
    console.log('>>', res);
    router.reload();
    setchange(!change)
    
  }
  return (
    <>
      <Paper sx={{ px: 2, pt: 2 }}>
        <Stack spacing={3}>
          <Box
            width={100}
            height={100}
            position='relative'
            borderRadius='50%'
            overflow='hidden'
            alignSelf='center'
          >
            <Image
              src={userStore.profile.image || '/assets/images/mock/profile.jpg'}
              layout='fill'
              objectFit='cover'
              alt={mock.lorem(1)}
            />
            <input type='file' accept='image/*' name='profile' id='img-uploader' onChange={ImageUpload} style={{display: 'none'}}  />
          </Box>
            {token && <Typography fontWeight='medium' style={{textAlign: 'center', cursor: 'pointer'}}  onClick={uploadImage} >
                 {language.id === 1 ? 'ارفع صورة': 'Update Image'}
            </Typography>}
          <Box>
            <Typography fontWeight='medium'>
              <TicketFilledIcon /> {userStore.profile.points || 0} {language.id === 1 ?'نقاط': 'Points'}
            </Typography>
            <Box py={1}/>
            <Typography fontWeight='medium'>

              <ShareRounded /> {language.id === 1 ?'ادع صديقك': 'Refer A Friend'}: <br/>
              <Box px={2}/>
              Android {language.id === 1 ? 'تطبيق':'App'}:
              <a id='ref-face' target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=https://api.al-tuhoo.com/invite/${userStore.profile.id}/android`} style={{margin: '0 3px', textDecoration: 'none',color: 'inherit'}} >
              <FacebookRounded /> 
              </a>
              <a id='ref-whats'  target='_blank' href={`https://api.whatsapp.com/send?text=https://api.al-tuhoo.com/invite/${userStore.profile.id}/android`} style={{margin: '0 3px', textDecoration: 'none',color: 'inherit'}} >
               <WhatsApp /> 
              </a>
              <a id='ref-tele'  target='_blank' href={`https://telegram.me/share/url?url=https://api.al-tuhoo.com/invite/${userStore.profile.id}/android`} style={{margin: '0 3px', textDecoration: 'none',color: 'inherit'}} >
                <Telegram />
              </a>
              <Box px={2}/>
              IOS {language.id === 1 ? 'تطبيق':'App'}:
              <a id='ref-face' target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=https://api.al-tuhoo.com/invite/${userStore.profile.id}/ios`} style={{margin: '0 3px', textDecoration: 'none',color: 'inherit'}} >
              <FacebookRounded /> 
              </a>
              <a id='ref-whats'  target='_blank' href={`https://api.whatsapp.com/send?text=https://api.al-tuhoo.com/invite/${userStore.profile.id}/ios`} style={{margin: '0 3px', textDecoration: 'none',color: 'inherit'}} >
               <WhatsApp /> 
              </a>
              <a id='ref-tele'  target='_blank' href={`https://telegram.me/share/url?url=https://api.al-tuhoo.com/invite/${userStore.profile.id}/ios`} style={{margin: '0 3px', textDecoration: 'none',color: 'inherit'}} >
                <Telegram />
              </a>
            </Typography>
            <List>
              <ListItemButton onClick={() => setExpanded(v => !v)}>
                <ListItemText primary={language.id === 1 ?'الحساب': 'Account'} />
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={expanded} timeout='auto' unmountOnExit>
                <List disablePadding>{accountList}</List>
              </Collapse>
              {baseList}
            </List>
          </Box>
        </Stack>
      </Paper>

      {!onUpdate ? null : (
        <>
          <Box py={1} />
        </>
      )}
    </>
  );
};
