import merge from 'lodash/merge';
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
  Direction,
  Theme,
  ThemeOptions,
} from '@material-ui/core/styles';
import { hsla } from '@/utils';
import { Options } from '@material-ui/core';

declare module '@material-ui/styles' {
  interface DefaultTheme extends Theme {}
}

declare module '@material-ui/core' {
  function useMediaQuery<T = Theme>(
    query: string | ((theme: T) => string),
    options?: Options
  ): boolean;
}

interface ThemeConfig {
  direction?: Direction;
}

const primary = 'hsl(356, 71%, 28%, 1)';
const secondary = 'hsl(42, 100%, 50%, 1)';

const baseOptions: ThemeOptions = {
  palette: {
    primary: {
      light: hsla.fromString(primary, { lightness: 8 }),
      main: hsla.fromString(primary),
      dark: hsla.fromString(primary, { lightness: -8 }),
      contrastText: '#fff',
    },
    secondary: {
      light: hsla.fromString(secondary, { lightness: 8 }),
      main: hsla.fromString(secondary),
      dark: hsla.fromString(secondary, { lightness: -8 }),
      contrastText: '#fff',
    },
  },
  typography: {
    allVariants: {
      color: primary,
    },
  },

  shape: {
    borderRadius: 0,
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          },
        },
      },
    },
  },
};

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let theme = createMuiTheme(
    merge({}, baseOptions, {
      direction: config.direction,
    })
  );
  theme = responsiveFontSizes(theme);

  return theme;
};
