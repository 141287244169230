const Routes = {
    career: '/careers',
    home: '/get_home',
    news: '/get_news',
    contact: '/contact_us',
    departments: '/get_departments',
    positions: '/get_career_positions',
    about: '/get_about',
    ourTeam: '/get_our_teams',
    footerSettings: '/get_contacts',
    trustedbrands: '/get_our_trusted_brands',
    counters: '/get_counter',
    categories_parents: '/category/parents',
    category_by_parent: '/category/getByParent',
    allProducts: '/product/index',
    socialLog: '/auth/login_via_provider/loginUrl',
    promo_codes: '/get_promo_code',
    featured_products: '/product/getFeatured',
    products_by_name: '/product/getByName',
    products_by_category: '/product/getByCategory',
    products_by_price: '/product/getByPrice',
    products_by_price_sort: '/product/orderByPrice',
    get_favorite: '/customer/get_favorite',
    payment: '/payment/push',
    update_favorite: '/customer/update_favorite',
    get_events: '/customer/get_events',
    update_event: '/customer/update_event',
    products_by_date: '/product/orderByDate',
    get_media: '/get_media',
    products_by_id: '/product/getById',
    login: '/auth/login',
    profile: '/customer/profile',
    update_profile: '/customer/complete_profile',
    set_order: '/customer/set_order',
    list_orders: '/customer/get_orders_by_user',
    one_order: '/customer/get_order',
    prodsByPromo: '/customer/get_user_promo_code_info',
    remove_order: '/customer/remove_order',
    update_order: '/customer/update_order',
    get_dTime: '/get_disable_time',
    pointsConfig: '/get_point_mony',
    ddl_unit_types: '/get_unit_types',
    ddl_cities: '/get_deleviry',
    forget_pass: '/auth/forgot_password',
    reset_password: '/auth/reset_password',
    registration: '/auth/registration',
    verify_email: '/auth/verify_email',
    resend_verify: '/auth/resend_verify',
    login_via_provider: '/auth/login_via_provider',
    profile_image: '/customer/profile_image',
    get_docs: '/get_docs',
    subscribe: '/subscribe',
    terms: '/get_terms',
};

export default Routes;