import { useGlobalStyles } from '@/styles';
import { Box, IconButton, Typography } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import Image from 'next/image';

import React , {useState, useEffect, useContext}from 'react';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import { LanguageContext } from 'src/ui/components/layout';

interface Props {}

export const HomeOurBrands: React.FC<Props> = () => {

  const gs = useGlobalStyles();
  const language = useContext(LanguageContext);

  const [content,setContent] = useState<any>([]);
  const [Slides,setSlides] = useState<any>([]);
  const [Page,setPage] = useState<any>(1);
  const [Size,setSize] = useState<any>(6);
  
  useEffect(() => {
    const request = async ()=>{
      let res = await GetRequest('trustedbrands');
      let sz = 6;
      if(window.innerWidth > 820 && window.innerWidth < 1000){
        sz = 3;
        setSize(3);
      }else if(window.innerWidth < 820 ){
        sz = 2;
        setSize(2);
      }
      if(res && res.success){
        
        setContent(res.data)
        setSlides(res.data.slice(0,sz))
      }
    }
    request();
    console.log('Entered Sizze 1: ', window.innerWidth)
  }, [])
  useEffect(() => {
    console.log('Entered Sizze: ', Size)
    setSlides(content.slice(0,Size))
  }, [Size])
  
  useEffect(() => {

  }, [language.id,Slides])
  const handleScroll = (mode: any) => {
      
      if(mode === 1){
        setPage((p: any) => p-1);
        setSlides(content.slice(Size*(Page-1-1),(Page-1)*Size));
      }else{
        setPage((p: any) => p+1);
        setSlides(content.slice(Size*(Page+1-1),(Page+1)*Size));
      }
  }
  return (
    <Box className={gs.responsiveContainer}>
      <Typography variant='h4' mb={2} style={{textAlign: language.id === 1 ? 'center':'center'}} >
        {language.id === 1 ? 'علاماتنا التجارية الموثوقة' :<>Our Trusted <b>Brands</b></>}
      </Typography>
      <Box display='flex' alignItems='center'>
        <IconButton
          size='large'
          onClick={() => {
            if(Page !== 1){
              handleScroll(1);
            }
          }}
        >
          <KeyboardArrowLeft fontSize='inherit' />
        </IconButton>
        <Box flex={1}>
            <div className='' style={{display:'flex',justifyContent: 'center'}} >
              {Slides.map((el: any) => (
                <div key={el.id}>
                  <Box
                    position='relative'
                    height={70}
                    // width={200}
                    style={{width: '10vw'}}
                    marginLeft={3}
                    marginRight={3}
                    >
                    <Image
                      layout='fill'
                      objectFit='fill'
                      src={el.image}
                      alt={el.id}
                      loading='eager'
                    />
                  </Box>
                </div>
              ))}
          </div>
        </Box>
        <IconButton
          size='large'
          onClick={() => {
            // scrollNext();
            if(Page !== Math.ceil(content.length/Size)){
              handleScroll(2);
            }
          }}
        >
          <KeyboardArrowRight fontSize='inherit' />
        </IconButton>
      </Box>
    </Box>
  );
};
