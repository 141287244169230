import { Box, IconButton, Typography } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../layout';

type Props = {
  initialValue?: number;
  quantity: any;
  onChange?: (newValue: number) => number | void;
  getValue?: (val: any) => number | void;
};

export const NumberCounter: React.FC<Props> = ({ initialValue, onChange, getValue,quantity }) => {
  const [value, setValue] = useState(initialValue? initialValue:  1);
  const language = useContext(LanguageContext);
  useEffect(() => {
    console.log('we have qty: ', quantity)
  }, [language.id])

  useEffect(() => {
    getValue?.(value);
  }, [value])
  return (
    <Box
      display='flex'
      alignItems='center'
      width='max-content'
      sx={{ border: t => `2px solid ${t.palette.divider}` }}
      style={{float: language.id === 1? 'right': 'left', margin: '20px 0'}}
    >
      <IconButton
        disabled={value === 1}
        color='secondary'
        onClick={() =>
          setValue(v => {
            if (v <= 1) return 1;
            const val = onChange?.(v - 1);
            return typeof val === 'number' ? val : v - 1;
          })
        }
      >
        <Remove />
      </IconButton>
      <Typography mx={2} fontWeight={500}>
        {value}
      </Typography>
      <IconButton
        color='secondary'
        disabled={value == parseInt(quantity)}
        onClick={() =>
          setValue(v => {
            const val = onChange?.(v + 1);
            return typeof val === 'number' ? val : v + 1;
          })
        }
      >
        <Add />
      </IconButton>
    </Box>
  );
};
