import { Box, Typography, Button, Snackbar, Alert } from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import Image from 'next/image';
import { validValue } from '@/utils';
import useStore from 'src/logic/utils/create-store/cart-store';
import useLogStore from 'src/logic/utils/create-store/user-store';
import { LanguageContext } from '../../layout';
import { FavoriteBorderOutlined, FavoriteOutlined } from '@material-ui/icons';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import PostRequest from 'src/logic/utils/create-request/Helpers/PostRequest';

// type Props = {
//   id: string;
//   title?: string;
//   overview?: string;
//   price?: number;
//   image: string;
//   sale?: number | null;
//   children?: never;
//   favorite?: boolean;
//   quantity?: any;
//   size?: any;
//   uuid?: any;
//   color?: any;
// };

export const ProductCard: React.FC<any> = ({
  title,
  overview,
  price,
  image,
  sale,
  quantity,
  uuid,
  size,
  color,
  price_as_points,
  price_bussnies,
  product_sap_id,
  mini_more
}) => {
  const userStore = useLogStore((state: any) => state);
  const token = localStorage.getItem('token');
  const curr_product = {
    id: Date.now(),
    price:userStore.profile?.account_type?.id === 2 ? price_bussnies: price,
    quantity: quantity,
    curQuantity: 1,
    uuid,
    product_sap_id,
    product: {
      price: userStore.profile?.account_type?.id === 2 ? price_bussnies: price,
      title,
      price_as_points,
      with_points: false,
      size,
      color,
      images: [{
        thumbnail: image || '/assets/images/mock/item.webp'
      }]
    }
  }
  const addProduct = useStore((state: any) => state.addProduct);
  const currentCart = useStore((state: any) => state.products);
  const [Added, setAdded] = useState<boolean>(false);
  const [IsFav, setIsFav] = useState<boolean>(false);
  const language = useContext(LanguageContext);
  useEffect(() => { 
      if(token){
        const get = async () => {
          const res = await GetRequest('get_favorite');
          console.log('FAvs: ', product_sap_id,res.data);
          let _favs = res.data;
          _favs = Object.keys(_favs).map((k) => _favs[k])
          setIsFav(_favs.some((i:any) => i.uuid === uuid));
        }
        get();
      }
  }, [token])
  useEffect(() => { }, [language.id, IsFav,userStore.profile])
  useEffect(() => {
    // @ts-ignore
    let res = currentCart.find((c:any) => c.uuid === uuid);
    if(res){
      setAdded(true);
    }

  }, [Added]);

  const handleFav = async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if(!token){
        window.scrollTo(0,0);
        let openLogn = document.getElementById('open-login');
        if(openLogn){
          setTimeout(() => {
            openLogn?.click();
          }, 200);
        }
        userStore.setLogOut();
      }else{
        //product_sap_id
        let payload = {
          product_sap_id,
          is_add: !IsFav
        }
        const res = await PostRequest('update_favorite', payload);
        console.log('Fav Res: ', res)
        setIsFav(!IsFav);
      }
  }
  return (
    <Box
      position='relative'
      height='100%'
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      p={2}
      style={{transform: mini_more ? 'scale(0.88)': ''}}
      border={`1px solid #0002`}
      sx={{
        transition: '.27s',
        '&:hover': {
          transform: 'scale(1.02)',
          boxShadow: t => t.shadows[4],
        },
      }}
    >
      <div style={{position: 'relative'}}>
        {IsFav ? <FavoriteOutlined style={{zIndex: 99,position: 'absolute',top: 0,right: '10px'}} onClick={handleFav} />
        : <FavoriteBorderOutlined style={{zIndex: 99,position: 'absolute',top: 0,right: '10px'}} onClick={handleFav} />}
        <Snackbar style={{display: 'none'}} open={!userStore.user.login} onClose={userStore.setLogIn} autoHideDuration={3000} anchorOrigin={{vertical:'top', horizontal :'right'}}>
            <Alert severity="error">{language.id === 1 ? 'يجب ان تقوم بتسجيل الدخول!':'You Must Login First'}</Alert>
        </Snackbar>
        <Box height={250} position='relative'>
          <Image
            src={image || '/assets/images/mock/item.webp'}
            alt={title}
            layout='fill'
            objectFit='cover'
            loading='lazy'
          />
        </Box>
        <Typography mt={1} variant='h5' style={{color: 'black'}} fontWeight='medium'>
          {title}
        </Typography>
        <Typography style={{color: 'black'}} mt={1} fontWeight={300}>
          {overview ? overview: language.id === 1 ? 'لا يوجد وصف للمنتج':'No Description provided'}
        </Typography>
      </div>

      <Box display='flex' justifyContent='space-between' mt={2}>
        <Typography variant='h5' fontWeight='medium'>
          {userStore.profile?.account_type?.id === 2? price_bussnies: price} KWD <br/> 
          {price_as_points ? 
            <Typography variant='body1' fontWeight='small'>
              {`${price_as_points} Points`}
            </Typography>
          : <h2 style={{display: 'block'}}> </h2>}
        </Typography>
        {
          Added ? 
          <Button
            variant='contained'
            // color='default'
            style={{ color: 'white', background: "#7a151be6" }}
            sx={{ color: 'secondary.main' }}
            onClick={(e: any) => {
              e.preventDefault();

            }}
          >
            {language.id === 1 ? 'تمت اضافته':'Added'}
          </Button>
          : parseInt(quantity) > 0 ?
            <Button
              variant='contained'
              color='primary'
              sx={{ color: 'secondary.main' }}
              onClick={(e: any) => {
                e.preventDefault();
                if(!token){
                  window.scrollTo(0,0);
                  let openLogn = document.getElementById('open-login');
                  if(openLogn){
                    openLogn.click();
                  }
                  userStore.setLogOut();
                }else{
                  addProduct(curr_product);
                  setAdded(true);
                }
              }}
            >
              {language.id === 1 ? 'اضافة' :'ADD TO CART'}
            </Button> : 
            <Button
              variant='contained'
              // color='default'
              style={{ color: 'white', background: "#7a151be6" }}
              sx={{ color: 'secondary.main' }}
              onClick={(e: any) => {
                e.preventDefault();

              }}
            >
             {language.id === 1 ? 'نفذت الكمية':' Out OF Stock'}
            </Button>
        }
      </Box>
      {validValue(sale) ? (
        <Typography
          position='absolute'
          px={1}
          py={0.5}
          color='white'
          sx={{
            backgroundColor: t => t.palette.secondary.main,
            top: t => t.spacing(1),
            left: t => t.spacing(1),
          }}
        >
          SALE {sale}% OFF
        </Typography>
      ) : null}

    </Box>
  );
};
