import { useGlobalStyles } from '@/styles';
import { Box, MenuItem, Select, Stack } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React from 'react';

const WhiteSelect = styled(Select)({
  color: 'white',
  '& > svg': {
    fill: 'white',
  },
  '& > fieldset': {
    borderWidth: 0,
  },
});


export const Header = ({setlanguageId}: any) => {
  const gs = useGlobalStyles();
  const handleChange = (e: any) => {
    setlanguageId(e.target.value);
  }
  return (
    <Box
      className={gs.responsiveContainer}
      display='flex'
      justifyContent='flex-end'
      sx={{ backgroundColor: t => t.palette.primary.main }}
    >
      <Stack direction='row' spacing={2}>
        <WhiteSelect size='small' defaultValue={2} onChange={handleChange} >
          <MenuItem value={2}>English</MenuItem>
          <MenuItem value={1}>عربي</MenuItem>
        </WhiteSelect>

        <WhiteSelect size='small' defaultValue='kwd'>
          <MenuItem value='kwd'>Kuwait Dinars (KWD)</MenuItem>
        </WhiteSelect>
      </Stack>
    </Box>
  );
};
