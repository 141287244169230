import { Box, Typography } from '@material-ui/core';
import React, { memo } from 'react';

type Props = {
  Icon: React.FC;
  title: string;
};

export const SingleProductIconTitle: React.FC<Props> = memo(
  ({ Icon, title }) => (
    <Box display='flex' alignItems='center'>
      <Box bgcolor='#0001' p={1} sx={{ borderRadius: '50%' }}>
        <Icon />
      </Box>
      <Typography p={1} variant='h6'>
        {title}
      </Typography>
    </Box>
  )
);
