import { SvgIconProps, SvgIcon } from '@material-ui/core';

export const TicketFilledIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox='0 0 21 14'>
    <path
      d='M4.667,7.5H16.333v7H4.667ZM19.25,11A1.75,1.75,0,0,0,21,12.75v3.5A1.75,1.75,0,0,1,19.25,18H1.75A1.75,1.75,0,0,1,0,16.25v-3.5a1.75,1.75,0,0,0,0-3.5V5.75A1.75,1.75,0,0,1,1.75,4h17.5A1.75,1.75,0,0,1,21,5.75v3.5A1.75,1.75,0,0,0,19.25,11ZM17.5,7.208a.875.875,0,0,0-.875-.875H4.375a.875.875,0,0,0-.875.875v7.583a.875.875,0,0,0,.875.875h12.25a.875.875,0,0,0,.875-.875Z'
      transform='translate(0 -4)'
    />
  </SvgIcon>
);
