import { Box, Grid, useMediaQuery } from '@material-ui/core';
import Image from 'next/image';
import React from 'react';

type Props = {};

export const AuthSharedLayout: React.FC<Props> = ({ children }) => {
  const downLg = useMediaQuery(t => t.breakpoints.down('lg'));

  return (
    <div>
      <Grid container spacing={2}>
        {!downLg ? null : (
          <Grid item lg xs={12}>
            <Box position='relative' height='50vh'>
              <Image
                src='/assets/images/sign-up/woman.png'
                alt='man walking with woman'
                layout='fill'
                objectFit='cover'
                objectPosition='top'
              />
            </Box>
          </Grid>
        )}
        <Grid item lg xs={12}>
          {children}
        </Grid>
        {downLg ? null : (
          <Grid item lg xs={12}>
            <Box position='relative' height='100vh'>
              <Image
                src='/assets/images/sign-up/woman.png'
                alt='man walking with woman'
                layout='fill'
                objectFit='cover'
                objectPosition='top'
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <Box py={4} />
    </div>
  );
};
