import { SvgIconProps, SvgIcon } from '@material-ui/core';

export const CarOutlined: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox='0 0 53.867 38.833'>
    <g transform='translate(-0.122 -0.045)'>
      <g transform='translate(0.622 0.545)'>
        <path
          d='M607.571,347.576a6.9,6.9,0,1,0,6.9,6.9A6.909,6.909,0,0,0,607.571,347.576Zm0,12.013a5.112,5.112,0,1,1,5.111-5.114,5.12,5.12,0,0,1-5.111,5.114Zm3.9-17.971V331.374h7.182v10.244Zm9.017.9c0-.042-.038-.068-.045-.11V330.481a.9.9,0,0,0-.9-.9H602.5v-5.147a.894.894,0,0,0-.9-.893h-12.06a.892.892,0,0,0-.766.432l-18.028,30.038a.893.893,0,0,0,.77,1.353h3.023a.894.894,0,0,0,0-1.789H573.1l16.952-28.249h10.66v22.463a.894.894,0,1,0,1.789,0v-16.42h7.182v11.14a.892.892,0,0,0,.893.893h8.078v10.173h-2.095a.894.894,0,1,0,0,1.789h2.987a.893.893,0,0,0,.9-.9V342.623c.008-.042.048-.068.048-.11Zm-36.952,17.072a5.112,5.112,0,1,1,5.11-5.114,5.12,5.12,0,0,1-5.11,5.114Zm0-12.013a6.9,6.9,0,1,1-6.9,6.9,6.9,6.9,0,0,1,6.9-6.9Zm-9.3-5.089a.9.9,0,0,1-.9.9h-4.815a.894.894,0,1,1,0-1.789h4.814A.9.9,0,0,1,574.232,342.484Zm-5.716-5.076a.894.894,0,1,1,0-1.789H576.9a.894.894,0,0,1,0,1.789Zm0-6.01a.893.893,0,1,1,0-1.786h12.015a.893.893,0,0,1,0,1.785Zm30.058,22.181a.894.894,0,1,1,0,1.789h-6.05a.894.894,0,1,1,0-1.789Z'
          transform='translate(-567.622 -323.545)'
          stroke='currentColor'
          strokeWidth={1}
          fillRule='evenodd'
        />
      </g>
    </g>
  </SvgIcon>
);
