import { TableCell, TableRow } from '@material-ui/core';
import { tableCellClasses } from '@material-ui/core/TableCell';
import { styled } from '@material-ui/styles';

export const StyledTableCell = styled(TableCell)(({ theme: t }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: t.palette.common.white,
    color: t.palette.common.black,
    fontSize: '1.2rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '1rem',
  },
  [`&.${tableCellClasses.footer}`]: {
    fontSize: '1.2rem',
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme: t }) => ({
  backgroundColor: t.palette.action.hover,
  // '&:nth-of-type(odd)': {
  //   backgroundColor: t.palette.action.hover,
  // },
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));
