import axios from 'axios';

export const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Accept-Language': 'en',
    'x-api-key': process.env.NEXT_PUBLIC_BOOKING_API_KEY,
  },
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});
