import { CarOutlined, LockOutlined, ShopOutlined, StarOutlined } from '@/icons';
import { useGlobalStyles } from '@/styles';
import { Box, Grid } from '@material-ui/core';
import { Tag } from 'src/ui/components/dumb';
import React , {useState, useEffect, useContext}from 'react';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';
import { LanguageContext } from 'src/ui/components/layout';

interface Props {}

export const HomeTags: React.FC<Props> = () => {
  
  const gs = useGlobalStyles();
  const [content,setContent] = useState<any>([]);
  const language = useContext(LanguageContext);
  const icons = {
    speed: <CarOutlined fontSize='inherit' />,
    quality: <StarOutlined fontSize='inherit' />,
    nice: <ShopOutlined fontSize='inherit' />,
    'Only Secure Payments': <LockOutlined fontSize='inherit' />,
    'الامان': <LockOutlined fontSize='inherit' />,
  };
  useEffect(() => {
    const request = async ()=>{
      let res = await GetRequest('counters');
      if(res && res.success){
        let mapped = res.data.map((p:any) => ({
          ...p,
          des: p.text,
          // @ts-ignore
          icon: icons[p.title] ? icons[p.title] : '' // @ts-ignore
        }))
        mapped = mapped.filter((s: any) => s.language_id === language.id);
        mapped = mapped.filter((s: any) => s.status === '1');
        setContent(mapped)
      }
    }
    request();
  }, [language.id])
  return (
    <Box className={gs.responsiveContainer}>
      <Grid container spacing={2}>
        {content.map((el:any) => (
          <Grid item key={el.title} xl lg md={6} sm={12} xs={12}>
            <Tag {...el} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
