import React, { useContext } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  Typography,
} from '@material-ui/core';
import { StyledTableRow, StyledTableCell } from '@/components';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { NumberCounter, ProductCell } from 'src/ui/components/dumb';
import useStore from 'src/logic/utils/create-store/cart-store';
import { useEffect} from 'react';
import { LanguageContext } from 'src/ui/components/layout';
import useLogStore from 'src/logic/utils/create-store/user-store';
import { useState } from 'react';

type Props = {
  onNext?: () => void;
  onPrev?: () => void;
};


export const CheckOutShoppingCartPage: React.FC<Props> = ({
  onNext,
  onPrev,
}) => {
  
  const userStore = useLogStore((state: any) => state);
  const dataz = useStore((state: any) => state.products);
  const removeProd = useStore((state: any) => state.removeProduct);
  const removeToke = useStore((state: any) => state.removeTokePromo);
  const updateProd = useStore((state: any) => state.updateProduct);
  const setTokePromo = useStore((state: any) => state.setTokePromo);
  const TokePromo = useStore((state: any) => state.TokePromo);
  const language = useContext(LanguageContext);
  const [wantedPoints, setWantedPoints] = useState<any>(0);
  const [Err, setErr] = useState<any>(false);
  const [Msg, setMsg] = useState<any>('');
  const _errors = {
     expire: ['Sorry! Promocode is expired', 'عذرا! انتهت صلاحية هذا الكود'],
     notTrue: ['Please Enter Correct Code for this product', 'أدخل كود الخصم الصحيح لهذا المنتج'],
     haveNo: ['Sorry! No promocode offered for this product', 'عذرا! لا يوجد كود خصم لهذا المنتج'],
     youDont: ['Sorry! You are not offered this promocode', 'عذرا! هذا الكود غير مخصص لك او غير صحيح'],
     inUse: ['Sorry! You already used this promocode on a product', 'عذرا! لقد قمت باستخدام هذا الكود هلى منتج اخر'],
  }
  const [TargetProduct, setTargetProduct] = useState<any>('');
  const openEnterPromo = (id: any) => {
    setTargetProduct(id);
    setOpen(true);
  };
  const submitCode = (e: any) => {
    e.preventDefault();
    const form = document.getElementById('code-form');
    if(form){
      // @ts-ignore
      const formData = new FormData(form);
      // formData.get('promo_code')  TargetProduct
      let entered = formData.get('promo_code');
      // @ts-ignore
      let _code = userStore.profile.promo_code.find((c:any) => c.code.toLocaleLowerCase() === entered.toLocaleLowerCase() );
      // .includes(TargetProduct)
      if(_code){
        // @ts-ignore
        if(_code.products.find((p:any) => p.includes(TargetProduct))){
          let exp = _code.expiry.split('-');
          if(new Date() > new Date(exp[0],exp[1],exp[2])){
            // Expired
            setMsg(language.id === 1 ? _errors.expire[1] : _errors.expire[0]);
            setErr(true);
          }else{
            if(TokePromo[_code.code]){
              setMsg(language.id === 1 ? _errors.inUse[1] : _errors.inUse[0]);
              setErr(true);
              return;
            }
            let _payload :any= {}
            _code.products.forEach((c: any) => {
              _payload[c] = false;
            });
            _payload[TargetProduct] = true;
            setTokePromo(_code.code,_payload);
            let _prod = dataz.find((d:any) => d.product_sap_id === TargetProduct);
            updateProd({..._prod, 
              price: _code.is_percentage ? (_prod.product.price)-((_prod.product.price*_code.amount)/100) : Math.max(_prod.product.price-_code.amount,0),
              product: {
                ..._prod.product,
                price_before_promo:_prod.product.price,
                promo_code: _code.code,
                price: _code.is_percentage ? (_prod.product.price)-((_prod.product.price*_code.amount)/100) : Math.max(_prod.product.price-_code.amount,0)
              }});
              setOpen(false);
              // @ts-ignore
              form.reset();
          }
        }else{
          // wrong code for this product
          setMsg(language.id === 1 ? _errors.notTrue [1]: _errors.notTrue[0]);
          setErr(true);
        }
      }else{
        // This product has no code for you
        setMsg(language.id === 1 ? _errors.youDont[1] : _errors.youDont[0]);
        setErr(true);
      }
      console.log('Target: ', _code);      
    }

  };
  useEffect(() => {
    // @ts-ignore
    let _pts = dataz.reduce((acc:any, el:any) => (acc += el.product.with_points && el.product.price_as_points * el.curQuantity), 0);
    setWantedPoints(_pts)
  }, [dataz,userStore.profile,language.id])
  const checkChange = ({target}:any) => {
    let _prod = dataz.find((d:any) => d.uuid === target.name);
    let tKey = Object.keys(TokePromo).find((k:any) => TokePromo[k][_prod.product_sap_id]);
    if(tKey) {removeToke(tKey);}
    updateProd({
       ..._prod,
       price: !target.checked ? _prod.product.price_before_promo ? _prod.product.price_before_promo : _prod.price : _prod.price,
       product: {
      ..._prod.product,
      price: !target.checked ? _prod.product.price_before_promo ? _prod.product.price_before_promo : _prod.price : _prod.price,
      with_points: target.checked
    }})
  }
  const getUserPoints = () => {
    return userStore.profile.points ? parseFloat(userStore.profile.points):0;
  }
  const [open, setOpen] = useState(false)
  return (
    <Box>
        <Snackbar open={Err} onClose={setErr} style={{direction: language.id === 1 ? 'rtl' : 'ltr'}} autoHideDuration={3000} anchorOrigin={{vertical:'top', horizontal :'center'}}>
            <Alert severity="error" style={{fontSize: '16px'}} >
               {Msg}
            </Alert>
        </Snackbar>
      <Dialog open={open} onClose={()=> {setOpen(false)}} >
        <DialogTitle>Add Promocode</DialogTitle>
            <Box component={'form'} onSubmit={submitCode} id='code-form' >
        <DialogContent>
              <TextField type='text' required name='promo_code' />
        </DialogContent>
        <DialogActions>
            <Button variant='outlined' onClick={()=> setOpen(false)} >Cancel</Button>
            <Button type='submit' variant='contained' >Add</Button>
        </DialogActions>
            </Box>
      </Dialog>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell width='40%'>{language.id === 1 ? 'المنتج' :'Product'}</StyledTableCell>
              <StyledTableCell width='20%'>{language.id === 1 ?  'السعر' :'Price'}</StyledTableCell>
              <StyledTableCell width='10%'>{language.id === 1 ?  'استخدام النقاط' :'Use Points'}</StyledTableCell>
              <StyledTableCell width='10%'>{language.id === 1 ?  'استخدام كود خصم' :'Use Promocode'}</StyledTableCell>
              <StyledTableCell width='10%'>{language.id === 1 ?  'الكمية' :'Quantity'}</StyledTableCell>
              <StyledTableCell width='20%'>{language.id === 1 ?  'الاجمالي' :'Total'}</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {dataz.map((el: any) => (
              <StyledTableRow key={el.id}>
                <StyledTableCell>
                  <ProductCell
                    title={el.product.title}
                    color={el.product.color ? el.product.color : language.id === 1 ? 'غير محدد' :'Not specified'}
                    size={el.product.size? el.product.size: language.id === 1 ? 'غير محدد' :'Not specified'}
                    price={el.product.price}
                    image={el.product.images[0].thumbnail}
                    onClose={() => {
                      removeProd(el.id);
                      let tKey = Object.keys(TokePromo).find((k:any) => TokePromo[k][el.product_sap_id]);
                      if(tKey) {removeToke(tKey);}
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell
                  sx={{ color: 'primary.main', fontWeight: 500 }}
                >
                  {el.price} KWD
                  {el.product.price_as_points ? 
                      <Typography variant='body1' fontWeight='small'>
                        {`${el.product.price_as_points} ${language.id === 1 ? 'نقاط':'Points'}`}
                      </Typography>
                    : ''}
                </StyledTableCell>
                <StyledTableCell>
                <FormGroup>
                    <FormControlLabel
                      control={<Checkbox disabled={!el.product.price_as_points} defaultChecked={el.product.with_points} name={el.uuid} onChange={checkChange} />}
                      label={''}
                    />
                  </FormGroup>
                </StyledTableCell>
                <StyledTableCell>
                    <Button size='small' variant='contained' disabled={Object.keys(TokePromo).find((c:any) => TokePromo[c][el.product_sap_id] ) || el.product.with_points} onClick={()=> openEnterPromo(el.product_sap_id)} >promocode</Button>
                </StyledTableCell>
                <StyledTableCell>
                  <NumberCounter 
                      initialValue={el.curQuantity} 
                      quantity={el.quantity}
                      getValue={function updateCart(newVal){
                        updateProd({...el, curQuantity: newVal})
                    }} />
                </StyledTableCell>
                <StyledTableCell
                  sx={{ color: 'primary.main', fontWeight: 500 }}
                >
                  {
                  el.product.with_points ? 0 :
                  el.product.price_before_promo ? parseFloat((el.product.price_before_promo * el.curQuantity).toFixed(2)) - (el.product.price_before_promo - el.price): parseFloat((el.price * el.curQuantity).toFixed(2))
                  } KWD
                  {el.product.with_points? 
                      <Typography variant='body1' fontWeight='small'>
                        {`${el.product.price_as_points* el.curQuantity} ${language.id === 1 ? 'نقاط':'Points'}`}
                      </Typography>
                    : ''}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box py={2} />
      <Box textAlign={language.id === 1?'start':'end'} style={{direction: language.id === 1? 'rtl' : 'ltr'}} >
        <Typography display='inline' style={{direction: language.id === 1? 'rtl' : 'ltr'}} >
          {language.id === 1 ? 'المجموع' : 'Subtotal'}  : </Typography>
        <Typography
          variant='h5'
          textAlign='end'
          display='inline'
          fontWeight='bold'
          style={{direction: language.id === 1? 'rtl' : 'ltr'}}
        >
          {/* @ts-ignore */}
          {dataz.reduce((acc, el) => (acc += !el.product.with_points && (el.product.price_before_promo ? parseFloat((el.product.price_before_promo * el.curQuantity).toFixed(2)) - (el.product.price_before_promo - el.price) : parseFloat((el.price * el.curQuantity).toFixed(2)) ) ), 0)} KWD
          {/* @ts-ignore */}
        </Typography>
        <br/>
        <Typography display='inline' style={{direction: language.id === 1? 'rtl' : 'ltr'}} >
          {language.id === 1 ? 'النقاط' : 'Points'}  : </Typography>
        <Typography
          variant='h5'
          textAlign='end'
          display='inline'
          fontWeight='bold'
          style={{direction: language.id === 1? 'rtl' : 'ltr'}}
        >
          {/* @ts-ignore */}
          {dataz.reduce((acc, el) => (acc += el.product.with_points && el.product.price_as_points * el.curQuantity), 0)} {language.id === 1 ? 'نقاط':'Points'}
          {/* @ts-ignore */}
        </Typography>
        <br/>
        <Typography display='inline' style={{direction: language.id === 1? 'rtl' : 'ltr'}} >
          {language.id === 1 ? 'لديك' : 'You Have'}  : </Typography>
        <Typography
          textAlign='end'
          display='inline'
          style={{direction: language.id === 1? 'rtl' : 'ltr'}}
        >
          {/* @ts-ignore */}
          {userStore.profile.points ? parseFloat(userStore.profile.points):0} {language.id === 1 ? 'نقاط':'Points'}
          {/* @ts-ignore */}
        </Typography>
      </Box>
      <Box py={1} />
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Button
          variant='text'
          size='large'
          onClick={onPrev}
          startIcon={<KeyboardArrowLeft color='secondary' />}
        >
          {language.id === 1? 'اكمل التسوق':'Continue Shopping'}
        </Button>
        <Button
          variant='contained'
          size='large'
          disabled={!dataz.length || parseFloat(wantedPoints) > getUserPoints() ? true: false}
          onClick={function next(){
            if(dataz.length && parseFloat(wantedPoints) <= getUserPoints()){
              onNext?.();
            }
          }
          }
          endIcon={<KeyboardArrowRight color='secondary' />}
        >
          {language.id === 1? 'معلومات الشحن':'Shipping Info'}
        </Button>
      </Box>
    </Box>
  );
};
